import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import './login.css';
import Otp from '../otp/Otp';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import Register from './Register';

function Login() {
    const [formSwitch, setFormSwitch] = useState("login");
    const [openOtpModal, setOpenOtpModal] = useState(false); // State for OTP modal

    const handleOpenOtpModal = () => setOpenOtpModal(true);
    const handleCloseOtpModal = () => setOpenOtpModal(false);

   
    


    return (
        <div className="login-container">
            <div className="login-subcontainer">
                <div className="login-banner-img-container">
                    <img
                        src="https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/2023/1/25/f5e9a029-33c3-4a92-811b-ef7917fe6d441674670210687-offer-banner-300-600x240-code-_-MYNTRA300.jpg"
                        alt=""
                    />
                </div>
                <div className="login-input-container-field">
                    {formSwitch === "login" ? (
                      <LoginForm setFormSwitch={ setFormSwitch} handleOpenOtpModal = {handleOpenOtpModal}/>
           
                    ) : (
                        <Register handleOpenOtpModal = {handleOpenOtpModal} setFormSwitch={ setFormSwitch}/>
                    )}
                   
                </div>
            </div>

            <Modal open={openOtpModal} >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Otp  handleCloseOtpModal = {handleCloseOtpModal} />
                </Box>
            </Modal>
        </div>
    );
}

export default Login;

