import React, { useEffect, useState } from "react";
import { getSimilarProduct } from "../../services/apiServices";
import Card from "../../components/Card";
import '../Shop/RelatedProducts.css';

const SimilarPrdocts = ({ productId }) => {
  const [similarPrdocts, setsimilarPrdocts] = useState([]);

  useEffect(() => {

if(productId){
  similarProduct();

}
  }, [productId]);

  const similarProduct = async () => {
    try {
      const response = await getSimilarProduct(productId);
      if (response && response.data) {
        setsimilarPrdocts(response.data);
      } else {
        setsimilarPrdocts([]);
      }
    } catch (error) {
      console.error("Error fetching similar products:", error);
      setsimilarPrdocts([]);
    }
  };

  return (
    <>
    <div className="row">
      <h3 className="related-title">Related Products</h3>
      {similarPrdocts?.map((product, index) => (
        <div key={index} className="custom-col mb-4">
          <Card product={product} />
        </div>
      ))}
    </div>
  </>
  );
};

export default SimilarPrdocts;
