import React from "react";
import RangeSlider from "./RangeSlider/RangeSlider";
const PriceRangeFilter = () => {
  return (
    <div className="mb-2">
      <RangeSlider />
    </div>
  );
};

export default PriceRangeFilter;
