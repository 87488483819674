import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const changePassword = async(payload,token)=>{
    try {
        const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        const password ={
            password:payload
        }
        const response = await apiService.post('/change-password', password, config)
        return response.data
    } catch (error) {
        
    }
}