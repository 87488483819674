import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPageData } from "../../services/SectionService";
import Section14 from "../Home/Section14/Section14";
import Section13 from "../Home/Section13/Section13";
import Section12 from "../Home/Section12/Section12";
import Section11 from "../Home/Section11/Section11";
import Section9 from "../Home/Section9/Section9";
import Section10 from "../Home/Section10/Section10";
import Section8 from "../Home/Section8/Section8";
import Section7 from "../Home/Section7/Section7";
import Section6 from "../Home/Section6/Section6";
import Section5 from "../Home/Section5/Section5";
import Section4 from "../Home/Section4/Section4";
import Section3 from "../Home/Section3/Section3";
import Section2 from "../Home/Section2/Section2";
import Section1 from "../Home/Section1/Section1";
import Section15 from "./Section15/Section15";
import ExtraSection1 from "./ExtraSection1/ExtraSection1";
import ExtraSection4 from "./ExtraSection4/ExtraSection4";
import ExtraSection2 from "./ExtraSection2/ExtraSection2";
import ExtraSection3 from "./ExtraSection3/ExtraSection3";

const ExtraSections = () => {
  const { slug } = useParams();
  const [sections, setSections] = useState({});
  const [data, setData] = useState([]);
  const pageIdMap = {
    mens: 2,
    kids: 3,
    "home-and-living": 4,
    electronics: 5,
    womens: 7,
    explore: 6,
  };

  const pageId = pageIdMap[slug] || 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPageData(pageId);

        if (!response.data) {
          console.error("API response is empty or null");
          setData([]); // Reset data
          setSections({}); // Reset sections
          return;
        }

        setData(response.data);

        const groupedData = response.data.reduce((acc, section) => {
          const { section_name, section_group, place_order } = section;

          if (!acc[place_order]) {
            acc[place_order] = {};
          }

          if (!acc[place_order][section_name]) {
            acc[place_order][section_name] = {};
          }

          if (!acc[place_order][section_name][section_group]) {
            acc[place_order][section_name][section_group] = [];
          }

          acc[place_order][section_name][section_group].push(section);
          return acc;
        }, {});

        setSections(groupedData);
      } catch (error) {

        setData([]);
        setSections({});
      }
    };

    fetchData();
  }, [slug, pageId]);

  const renderComponent = (sections) => {
    if (!sections || Object.keys(sections).length === 0) {
      return <div>No data available</div>;
    }

    return Object.keys(sections).map((placeOrderKey) => {
      const placeOrder = sections[placeOrderKey];
      return Object.keys(placeOrder).map((sectionName) => {
        const sectionGroups = placeOrder[sectionName];

        return Object.keys(sectionGroups).map((sectionGroup) => {
            switch (sectionName) {
                case "section_18":
                  return (
                    <ExtraSection1
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_19":
                  return (
                    <ExtraSection3
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_20":
                  return (
                    <ExtraSection4
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_21":
                  return (
                    <ExtraSection2
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                  case "section_1":
                  return (
                    <Section1
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                  case "section_2":
                  return (
                    <Section2
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                  case "section_3":
                  return (
                    <Section3
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_4":
                  return (
                    <Section4
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                  case "section_5":
                    return (
                      <Section5
                        key={sectionGroup}
                        sections={sectionGroups[sectionGroup]}
                      />
                    );
                case "section_6":
                  return (
                    <Section6
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_7":
                  return (
                    <Section7
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_8":
                  return (
                    <Section8
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_9":
                  return (
                    <Section9
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_10":
                  return (
                    <Section10
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_11":
                  return (
                    <Section11
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_12":
                  return (
                    <Section12
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_13":
                  return (
                    <Section13
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                case "section_14":
                  return (
                    <Section2
                      key={sectionGroup}
                      sections={sectionGroups[sectionGroup]}
                    />
                  );
                  case "section_15":
                    return (
                      <Section15
                        key={sectionGroup}
                        sections={sectionGroups[sectionGroup]}
                      />
                    );
                    case "section_16":
                    return (
                      <Section5
                        key={sectionGroup}
                        sections={sectionGroups[sectionGroup]}
                      />
                    );
                    case "section_17":
                    return (
                      <Section6
                        key={sectionGroup}
                        sections={sectionGroups[sectionGroup]}
                      />
                    );
                default:
                  return null;
              }
        });
      });
    });
  };

  return <main>
    {renderComponent(sections)}
    </main>;
};

export default ExtraSections;
