import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getUserCartTotalDetailsApi } from "../../services/CartService";

export const fetchCartAmountDetials= createAsyncThunk(
    "/get-total-amount",
    async (token) => {
      const response = await getUserCartTotalDetailsApi(token);
      return response.data; 
    }
  );
const initialState = {
    loading: true,
    error: null,
  cartTotalAmount: {},
};

export const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCartAmountDetials.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchCartAmountDetials.fulfilled, (state, action) => {
        state.cartTotalAmount = action.payload;
        state.loading = false;
      })

      .addCase(fetchCartAmountDetials.rejected, (state, action) => {
        state.loading = false;
        state.cartTotalAmount = {}

        state.error = action.error.message || "error redux found";
      });;
  },
});

// Export the action creator generated by createSlice
export const {  } = cartSlice.actions;

// Export the reducer function generated by createSlice
export default cartSlice.reducer;
