import React from "react";
import "./select.css"
import { useShopContext } from "./ShopContext";
const SortingDropdown = ({ sortValue, setSortValue }) => {
 
const {handleSortChange}=useShopContext();
  return (
    <div>
    <div className="d-flex  mb-4">
      <p className="mb-0">Sort By:</p>
      <select className="mx-2 custom-select" value={sortValue}
       onChange={handleSortChange}
       >
        <option  value="asc">Ascending</option>
        <option value="desc">Descending</option>
        <option value="price_asc">Price-Ascending</option>
        <option value="price_desc">Price-Descending</option>
      </select>
    </div>
    </div>
  );
};

export default SortingDropdown;
