import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AddressSchema } from "./validation";
import { fetchCartAmountDetials } from "../../../Redux/Slice/cartSlice";
import {
  createBillingAddressApi,
  createShippingAddressApi,
  getBillingAddress,
  getShippingddress,
  updateBillingAddressApi,
  updateShippingAddressApi,
} from "../../../services/AddressService";
import { placeOrderCreate } from "../../../services/CartService";
import AddressDetails from "./AddressList";
import { FaTag } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import AddressForm from "../../../components/AddressForm/AddressForm";
import AppModal from "../../../components/AppModal/AppModal";
const billingInitialValues = {
  name: "",
  mobile_no: "",
  email: "",
  address_line_one: "",
  address_line_two: "",
  pincode: "",
  country_id: "",
  state_id: "",
  city_id: "",
  is_default: false,
};
const shippingInitialValues = {
  name: "",
  mobile_no: "",
  email: "",
  address_line_one: "",
  address_line_two: "",
  pincode: "",
  country_id: "",
  state_id: "",
  city_id: "",
  is_default: false,
};
const AddressBook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAddressForm, setIsAddressForm] = useState(false);
  const token = localStorage.getItem("token");
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [activeTab, setActiveTab] = useState("description");
  const [mode, setMode] = useState("Add");
  const [isDisabled, setIsDisabled] = useState(false);
  const handleTabClick = (tab) => {
    if (mode !== "Edit") {
      setActiveTab(tab);
    }
  };
  const billingAddressFormik = useFormik({
    initialValues: billingInitialValues,
    validationSchema: AddressSchema,
    onSubmit: handleBillingSubmit,
  });
  const shippingAddressFormik = useFormik({
    initialValues: shippingInitialValues,
    validationSchema: AddressSchema,
    onSubmit: handleShippingSubmit,
  });

  useEffect(() => {
    dispatch(fetchCartAmountDetials(token));
  }, []);
  useEffect(() => {
    getShippingAddressData(token);
    getBillingAddressData(token);
  }, []);

  const getShippingAddressData = async (token) => {
    try {
      const response = await getShippingddress(token);
      if (response?.status_code === 200) {
        setShippingAddress(response?.data || []);
      }
    } catch (error) {}
  };
  const getBillingAddressData = async (token) => {
    try {
      const response = await getBillingAddress(token);
      if (response?.status_code === 200) {
        setBillingAddress(response?.data || []);
      }
    } catch (error) {}
  };
  const [pincodeValidationError, setPincodeValidationError] = useState("");
  async function pincodeValidation(values) {
    let notError = false;
    try {
      const checkPinCode = Number(values.pincode);
      const validatePinCodeUrl = `https://api.postalpincode.in/pincode/${checkPinCode}`;
      const response = await fetch(validatePinCodeUrl);
      const data = await response.json();
      let resState = data[0]?.PostOffice[0]?.State?.replace(
        /\s+/g,
        ""
      )?.toLowerCase();
      let givenState = values?.state_name?.replace(/\s+/g, "")?.toLowerCase();
      let finalState = resState === givenState;

      if (data[0].Status === "Success" && finalState) {
        setPincodeValidationError("");
        return (notError = "Success");
      } else {
        setPincodeValidationError("Given Pin Code is InValid!!!");
        return;
      }
    } catch (error) {
      console.error("Error validating pincode:", error);
      setPincodeValidationError("Error validating pincode.");
      return;
    }
  }

  async function handleBillingSubmit(values) {
    setIsDisabled(true);
    pincodeValidation(values).then(async (x) => {
      if (
        Object.keys(billingAddressFormik.errors)?.length === 0 &&
        x === "Success"
      ) {
        setPincodeValidationError("");

        let billingData = {
          id: values?.id,
          billing_address_id: values?.id,
          billing_name: values?.name,
          billing_email: values?.email,
          billing_phone_no: values?.mobile_no,
          billing_address_line_one: `${values?.address_line_one}`,
          billing_address_line_two: values?.address_line_two,
          billing_pincode: values?.pincode,
          billing_set_default: values?.is_default == true ? 1 : 0,
          billing_country: values?.country,
          billing_state: values?.state,
          billing_city: values?.city,
          billing_is_active: 1,
        };
        try {
          let billing = function modeAPIBillingCall() {
            if (billingData?.id) {
              return updateBillingAddressApi(billingData, token);
            } else {
              return createBillingAddressApi(billingData, token);
            }
          };
          let apicall = billing();
          const response = await apicall;
          //   const response = await createBillingAddressApi(billingData, token);
          if (response.status_code == 200) {
            toast.success(
              `Billing address ${
                mode === "Edit" ? "Updated" : "Added"
              }  successfully!`
            );
            getBillingAddressData(token);
            if (mode === "Edit") {
              setIsAddressForm(false);
            }
            setActiveTab("specification");
            setIsDisabled(false);
          }
        } catch (error) {
          setIsDisabled(false);
        }
      }
    });
  }
  async function handleShippingSubmit(values) {
    setIsDisabled(true);

    pincodeValidation(values).then(async (x) => {
      if (
        Object.keys(shippingAddressFormik.errors)?.length === 0 &&
        x === "Success"
      ) {
        let shippingvalues = {
          id: values?.id,
          shipping_address_id: values?.id,
          shipping_name: values?.name,
          shipping_email: values?.email,
          shipping_phone_no: values?.mobile_no,
          shipping_address_line_one: `${values?.address_line_one}`,
          shipping_address_line_two: values?.address_line_two,
          shipping_pincode: values?.pincode,
          shipping_set_default: values?.is_default == true ? 1 : 0,
          shipping_country: values?.country,
          shipping_state: values?.state,
          shipping_city: values?.city,
          shipping_is_active: 1,
        };

        try {
          let shipping = function modeAPIShippingCall() {
            if (shippingvalues?.id) {
              return updateShippingAddressApi(shippingvalues, token);
            } else {
              return createShippingAddressApi(shippingvalues, token);
            }
          };
          let apicall = shipping();
          const response = await apicall;
          //   const response = await createShippingAddressApi(
          //     shippingvalues,
          //     token
          //   );
          if (response.status_code == 200) {
            toast.success(
              `Shipping address ${
                mode === "Edit" ? "Updated" : "Added"
              }  successfully!`
            );
            getShippingAddressData(token);
            setIsAddressForm(false);
            setActiveTab("description");
            billingAddressFormik.resetForm();
            shippingAddressFormik.resetForm();
            setIsDisabled(false);

          }
        } catch (error) {
          setIsDisabled(false);

        }
      }
    });
  }

  const handleBillingShippingSame = (e) => {
    shippingAddressFormik.setFieldValue("terms_agree", e.target.checked);
    if (e.target.checked) {
      shippingAddressFormik.setValues(billingAddressFormik.values);
    } else {
      shippingAddressFormik.resetForm();
    }
  };
  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState(null);

  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState(null);

  const handleBillingAddressSelect = (id) => {
    setSelectedBillingAddressId(id);
  };

  const handleShippingAddressSelect = (id) => {
    setSelectedShippingAddressId(id);
  };
  useEffect(() => {
    let defaultBillingAddressId = billingAddress?.find(
      (x) => x?.billing_set_default === 1
    );
    let defaultShippingAddressId = shippingAddress?.find(
      (x) => x?.shipping_set_default === 1
    );

    setSelectedBillingAddressId(defaultBillingAddressId?.billing_address_id);
    setSelectedShippingAddressId(defaultShippingAddressId?.shipping_address_id);
  }, [billingAddress, shippingAddress]);
  const placeOrderContinue = async () => {
    if (!selectedBillingAddressId || !selectedShippingAddressId) {
      toast.warning("Please select billing and shipping address !");
      return;
    }
    const payload = {
      billing_address_id: selectedBillingAddressId,
      shipping_address_id: selectedShippingAddressId,
    };
    try {
      const response = await placeOrderCreate(payload, token);
      if (response.status_code === 200) {
        toast.success("Order placed successfully !");
        const orderId = response.data;
        const data = { order_id: orderId };
        const encodedData = btoa(JSON.stringify(data));
        const laravelAppUrl = process.env.REACT_APP_LARAVEL_APP_URL;
        window.location.href = `${laravelAppUrl}/manual-payment?F1!sO=${encodedData}`;
        document.getElementById("placeOrderButton").disabled = false;
      }
    } catch (error) {
      document.getElementById("placeOrderButton").disabled = false;
      console.error(error);
    }
  };
  const handleEditForm = (data) => {
    setIsAddressForm(true);
    setMode("Edit");
    let billingData = {
      id: data?.billing_address_id,
      name: data?.billing_name,
      email: data?.billing_email,
      mobile_no: data?.billing_phone_no,
      address_line_one: `${data?.billing_address_line_one}`,
      address_line_two: data?.billing_address_line_two,
      pincode: data?.billing_pincode,
      is_default: data?.billing_set_default == 1 ? true : false,
      country: data?.billing_country_id,
      country_id: data?.billing_country_id,
      state_id: data?.billing_state_id,
      city_id: data?.billing_city_id,

      state: data?.billing_state_id,
      city: data?.billing_city_id,
      state_name: data?.billing_state,
      city_name: data?.billing_city,
    };
    let shippingData = {
      id: data?.shipping_address_id,
      name: data?.shipping_name,
      email: data?.shipping_email,
      mobile_no: data?.shipping_phone_no,
      address_line_one: `${data?.shipping_address_line_one}`,
      address_line_two: data?.shipping_address_line_two,
      pincode: data?.shipping_pincode,
      is_default: data?.shipping_set_default == 1 ? true : false,
      country: data?.shipping_country_id,
      country_id: data?.shipping_country_id,
      state_id: data?.shipping_state_id,
      city_id: data?.shipping_city_id,

      state: data?.shipping_state_id,
      city: data?.shipping_city_id,
      state_name: data?.shipping_state,
      city_name: data?.shipping_city,
    };
    if (data?.billing_address_id) {
      setActiveTab("description");
      billingAddressFormik.setValues(billingData);
    } else {
      setActiveTab("specification");
      shippingAddressFormik.setValues(shippingData);
    }
  };
  return (
    <div className="container-fluid card p-4 ">
      <div className="row">
        <div className="col-md-10 col-lg-12">
          <h3 className="text-center mb-4">Address Book</h3>
          <h5
            style={{
              fontSize: "12px",
              color: "#535766",
              fontWeight: 700,
              margin: "30px 0px 16px 2px",
            }}
          >
            Billing and Shipping Address
          </h5>
          <div
            className="mt-3"
            style={{
              minHeight: "500px",
              overflow: "auto",
              scrollBehavior: "smooth",
            }}
          >
            <div className="d-flex  justify-content-between">
              {(shippingAddress?.length === 0 ||
                billingAddress?.length === 0) && (
                <p className="text-center" style={{ fontSize: "12px" }}>
                  No Address Available
                </p>
              )}
              <div>
                {billingAddress?.length > 0 &&
                  billingAddress?.map((x) => {
                    return (
                      <div className="mt-2">
                        <AddressDetails
                          id={x?.billing_address_id}
                          isDefault={
                            x?.billing_set_default === 1 ? true : false
                          }
                          name={x?.billing_name || ""}
                          addressTag={"Billing"}
                          addressOne={x?.billing_address_line_one}
                          addressTwo={x?.billing_address_line_two}
                          country={x?.billing_country}
                          state={x?.billing_state}
                          city={x?.billing_city}
                          pincode={x?.billing_pincode}
                          mobileNo={x?.billing_phone_no}
                          selectedId={selectedBillingAddressId}
                          handleAddressSelect={handleBillingAddressSelect}
                          handleEdit={() => handleEditForm(x)}
                        />
                      </div>
                    );
                  })}
              </div>
              <div>
                {shippingAddress?.length > 0 &&
                  shippingAddress?.map((x) => {
                    return (
                      <div className="mt-2">
                        <AddressDetails
                          id={x?.shipping_address_id}
                          isDefault={
                            x?.shipping_set_default === 1 ? true : false
                          }
                          name={x?.shipping_name || ""}
                          addressTag={"Shipping"}
                          addressOne={x?.shipping_address_line_one}
                          addressTwo={x?.shipping_address_line_two}
                          country={x?.shipping_country}
                          state={x?.shipping_state}
                          city={x?.shipping_city}
                          pincode={x?.shipping_pincode}
                          mobileNo={x?.shipping_phone_no}
                          selectedId={selectedShippingAddressId}
                          handleAddressSelect={handleShippingAddressSelect}
                          handleEdit={() => handleEditForm(x)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-between pointer"
            style={{
              border: "1px solid #eaeaec",
              marginTop: "20px",
              padding: "5px",
            }}
            onClick={() => setIsAddressForm(true)}
          >
            <button className="btn border-0 fw-bold cart-action-btn ">
              <FaTag /> Add New Address
            </button>
            <div>
              <MdKeyboardArrowRight size={25} style={{}} />
            </div>
          </div>
        </div>
      </div>
      {isAddressForm && (
        <AppModal
          title="Add New Address"
          size="lg"
          show={isAddressForm}
          onHide={() => {
            setIsAddressForm(false);
            billingAddressFormik.resetForm();
            shippingAddressFormik.resetForm();
            setActiveTab("description");
            setMode("Add");
          }}
          closeButton={true}
        >
          <div className="tab tab-nav-simple mt-1">
            <ul className="nav nav-tabs justify-content-center" role="tablist">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "description" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("description")}
                >
                  Billing
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "specification" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("specification")}
                >
                  Shipping
                </button>
              </li>
            </ul>
            <div className="tab-content">
              {activeTab === "description" && (
                <div className="tab-pane active in" id="product-tab-additional">
                  <AddressForm
                    activeTab={activeTab}
                    formik={billingAddressFormik}
                    pincodeValidationError={pincodeValidationError}
                    isDisabled={isDisabled}
                  />
                </div>
              )}
              {activeTab === "specification" && (
                <div className="tab-pane active in" id="product-tab-additional">
                  <AddressForm
                    activeTab={activeTab}
                    formik={shippingAddressFormik}
                    handleBillingShippingSame={handleBillingShippingSame}
                    pincodeValidationError={pincodeValidationError}
                    isDisabled={isDisabled}
                  />
                </div>
              )}
            </div>
          </div>
        </AppModal>
      )}
    </div>
  );
};

export default AddressBook;
