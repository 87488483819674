import React, { useEffect, useState } from "react";
import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import Section4 from "./Section4/Section4";
import Section6 from "./Section6/Section6";
import Section7 from "./Section7/Section7";
import Section9 from "./Section9/Section9";
import Section10 from "./Section10/Section10";
import Section12 from "./Section12/Section12";
import Section14 from "./Section14/Section14";
import Section5 from "./Section5/Section5";
import Section8 from "./Section8/Section8";
import Section11 from "./Section11/Section11";
import Section13 from "./Section13/Section13";
import { getPageData } from "../../services/SectionService";

const Home = () => {
  const [sections, setSections] = useState({});

  useEffect(() => {
    pageResponse();
  }, []);
  const pageResponse = async () => {
    try {
      const response = await getPageData(1);
      const data = response.data;

      if (!data) {
        console.error("API response is empty or null");
        return;
      }

      const groupedData = data.reduce((acc, section) => {
        const { section_name, section_group, place_order } = section;

        if (!acc[place_order]) {
          acc[place_order] = {};
        }

        if (!acc[place_order][section_name]) {
          acc[place_order][section_name] = {};
        }

        if (!acc[place_order][section_name][section_group]) {
          acc[place_order][section_name][section_group] = [];
        }

        acc[place_order][section_name][section_group].push(section);
        return acc;
      }, {});

      setSections(groupedData);
    } catch (error) {}
  };
  const renderComponent = (sections) => {
    if (!sections) return null;

    return Object.keys(sections).map((placeOrderKey) => {
      const placeOrder = sections[placeOrderKey];
      return Object.keys(placeOrder).map((sectionName) => {
        const sectionGroups = placeOrder[sectionName];
        return Object.keys(sectionGroups).map((sectionGroup) => {
          switch (sectionName) {
            case "section_1":
              return (
                <Section1
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_2":
              return (
                <Section2
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_3":
              return (
                <Section3
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_4":
              return (
                <Section4
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_5":
              return (
                <Section5
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_6":
              return (
                <Section6
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_7":
              return (
                <Section7
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_8":
              return (
                <Section8
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_9":
              return (
                <Section9
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_10":
              return (
                <Section10
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_11":
              return (
                <Section11
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_12":
              return (
                <Section12
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
            case "section_13":
              return (
                <Section13
                  key={sectionGroup}
                  sections={sectionGroups[sectionGroup]}
                />
              );
              case "section_22":
                return (
                  <Section14
                    key={sectionGroup}
                    sections={sectionGroups[sectionGroup]}
                  />
                );

            default:
              return null;
          }
        });
      });
    });
  };

  return (
    <div>
      {renderComponent(sections)}
      {/* <Section1 /> */}
      {/* <Section2 />
      <Section3/>
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11/>
      <Section12 />
      <Section13 />*/}
      {/* <Section14  />  */}
    </div>
  );
};

export default Home;
