import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchSettingsData = createAsyncThunk(
  "settings/fetchSettingsData",
  async () => {
    try {
      const response = await apiService.get("/settings-data");
      return response.data.data;
    } catch (error) {
      throw error; 
    }
  }
);

const initialState = {
  settingData: [],
  error: "",
  loading: false,
};

const userSettingsData = createSlice({
  name: "settingsData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettingsData.fulfilled, (state, action) => {
        state.loading = false;
        state.settingData = action.payload;
        state.error = ""; 
      })
      .addCase(fetchSettingsData.rejected, (state, action) => {
        state.loading = false;
        state.error = "Error fetching settings data"; 
      });
  },
});

export default userSettingsData.reducer;
