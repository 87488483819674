import React, { startTransition } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "../../services/HeaderServices";
import { toast } from "react-hot-toast";
import "./dashboard.css";
const OrderSideBar = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();

  const navigate = useNavigate();
  const logout = async () => {
    try {
      const response = await handleLogout(token);
      if (response.status_code === 200) {
        localStorage.removeItem("token");
        navigate("/login");
        toast.success("logout successfully");
      }
    } catch (error) {}
  };
  return (
    <div className=" card border  h-75">
      <div className="mt-4 p-3">
        <div className="text-center">
          <h5 className="mb-2">Profile</h5>
        </div>
        <hr />
        <div className="d-flex flex-column gap-3">
          <a
            onClick={() => navigate("/dashboard")}
            className={`text-decoration-none  pointer ${
              location.pathname === "/dashboard" ? "active-color" : "text-dark"
            }`}
          >
            My Account
          </a>
          <a
            onClick={() => {
              startTransition(() => navigate("pre-order"));
            }}
            className={`text-decoration-none  pointer ${
              location.pathname === "/dashboard/pre-order"
                ? "active-color"
                : "text-dark"
            }`}
          >
           Preorders
          </a>
          <a
            onClick={() => navigate("order-details")}
            className={`text-decoration-none  pointer ${
              location.pathname === "/dashboard/order-details"
                ? "active-color"
                : "text-dark"
            }`}
          >
            Orders
          </a>
          <a
            onClick={() => navigate("address-book")}
            className={`text-decoration-none  pointer ${
              location.pathname === "/dashboard/address-book"
                ? "active-color"
                : "text-dark"
            }`}
          >
            Address Book
          </a>
          <a
            onClick={() => navigate("change-password")}
            className={`text-decoration-none  pointer ${
              location.pathname === "/dashboard/change-password"
                ? "active-color"
                : "text-dark"
            }`}
          >
            Change Password
          </a>
          <a
            onClick={() => {
              startTransition(() => navigate("wishlist"));
            }}
            className={`text-decoration-none  pointer ${
              location.pathname === "/dashboard/wishlist"
                ? "active-color"
                : "text-dark"
            }`}
          >
            Wishlist
          </a>
          <a
            onClick={() => {
              startTransition(() => navigate("/contact-us"));
            }}
            className="text-decoration-none text-dark pointer"
          >
            Contact Us
          </a>
          <a
            onClick={logout}
            className="text-decoration-none text-dark pointer"
          >
            Logout
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderSideBar;
