import React, { useState } from 'react'
import { Button } from '@mui/material';
import {signup} from '../../services/apiServices'
import  toast  from 'react-hot-toast';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';




function Register({handleOpenOtpModal,setFormSwitch}) {


    const [userName ,setUsername]  =  useState("")
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [error,setError ] = useState("")


    const [showPassword ,setShowPassword]  = useState(false)

    const validateForm = () => {
        const errors = {};
        if(!userName) errors.userName  = "Username is required";
        if (!email) errors.email = 'Email is required';
        if (!mobile) errors.mobile = 'Mobile number is required';
        if (!password) errors.password = 'Password is required';
        if (password !== confirmPassword) errors.confirmPassword = 'Passwords must match';
        return errors;
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0 && isChecked) {
            try {
                const  payload = {
                    "full_name": userName ,
                    "email": email,
                    "phone_no": +mobile,
                    "password": password,
                    "confirmPassword": confirmPassword,
                    "terms_agree": isChecked ? 1 : 0
                }
                const response =  await signup(payload)
                if(response.status_code === 200){
                    localStorage.setItem("email", email);
                    toast.success(response.message)
                    handleOpenOtpModal();
                }

            } catch (error) {
                toast.error("Email already exists")
            }


            
        } else {
            setFormErrors(errors);
        }
    };

 

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleChange = (e, setter) => {
        setter(e.target.value);
    };
    const handleKeyPress = (event) => {
        if (!/[0-9]/i.test(event.key)) {
          event.preventDefault();
        }
      };

  return (
    <div className="register-form-container">
    <form  >

        <input
            className="input"
            type="text"
            placeholder="Username"
            value={userName}
            onChange={(e) => handleChange(e, setUsername)}   
            autoComplete="inputfiled"
        />

        {formErrors.userName && <p className="error">{formErrors.userName}</p>}
        <input
            className="input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => handleChange(e, setEmail)}
            autoComplete="email"
        />
        {formErrors.email && <p className="error">{formErrors.email}</p>}

        <input
       
            className="input"
            type="text"
            onKeyPress={handleKeyPress}
            placeholder="Mobile Number"
            value={mobile}
            maxLength={10}
            minLength={10}
            onChange={(e) => handleChange(e, setMobile)}
        />
        {formErrors.mobile && <p className="error">{formErrors.mobile}</p>}

        <input
            className="input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => handleChange(e, setPassword)}
            autoComplete="password"
        />
        {formErrors.password && <p className="error">{formErrors.password}</p>}


            <div className='login-pw-container'>

                <input
                    className="input"
                    type={showPassword  ? "text" : "password"}
                    placeholder="Confirm Password"
                    autoComplete="off"
                    value={confirmPassword}
                    onChange={(e) => handleChange(e, setConfirmPassword)}
                />
                <div onClick={() => setShowPassword((s) => !s)} style={{ cursor: "pointer" }}>
                    {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />} {/* Toggle icon */}
                </div>
            </div>
        {formErrors.confirmPassword && <p className="error">{formErrors.confirmPassword}</p>}

        <div className="reg-checkbox">
            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            <p>By continuing, I agree to the <span>Privacy Policy</span> &{' '}<span>Terms of Use.</span></p>
        </div>

        </form>
        <Button
            variant="contained"
            sx={{
            backgroundColor: '#FF3F6C',
            width: '100%',
            borderRadius: '0px',
            fontWeight:'600'
            }}
            type="submit"
            disabled={!isChecked}
            onClick={(e) => handleSubmit(e)}
        >Register</Button>

    <p className="already-have-acc">
    Have an account already ? {' '}
    <span onClick={() => setFormSwitch('login')}>Login.</span>
    </p>
</div>
  )
}

export default Register
