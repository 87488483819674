import React, { useEffect, useState } from "react";
import "./Section12.css";
import { getCampaignData } from "../../../services/SectionService";
import { useNavigate } from "react-router-dom";

const Section12 = ({ sections }) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const section = sections?.[0];
  const campaign = section?.campaign_data?.[0];
  const expireDate = campaign ? campaign.expire_date : null;
  const banner = campaign ? campaign.image_path : null;
  const title = sections.find((item) => item.key_name === "TITLE");
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  useEffect(() => {
    if (!expireDate) return;
    const [year, month, day] = expireDate.split("-");
    const countdownDate = new Date(
      `${year}-${month}-${day}T23:59:59`
    ).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown({
          days: String(days).padStart(2, "0"),
          hours: String(hours).padStart(2, "0"),
          minutes: String(minutes).padStart(2, "0"),
          seconds: String(seconds).padStart(2, "0"),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expireDate]);
  return (
    <div
      className="container-fluid section12-container my-5"
      style={{
        backgroundImage: `url(${AppUrl + campaign?.image_path})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="row">
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
          <div className="section12-first-content text-center">
            <h1>{campaign?.title}</h1>
            <h2>{campaign?.campaign_name}</h2>
            <p>{campaign?.description}</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
          <div className="section12-countdown d-flex justify-content-center">
            <div className="section12-time-box">
              <span>{countdown.days}</span>
              <span>Days</span>
            </div>
            <div className="section12-time-box">
              <span>{countdown.hours}</span>
              <span>Hours</span>
            </div>
            <div className="section12-time-box">
              <span>{countdown.minutes}</span>
              <span>Minutes</span>
            </div>
            <div className="section12-time-box">
              <span>{countdown.seconds}</span>
              <span>Seconds</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section12;
