import React, { useState } from "react";
import "./Section14.css";
import { useNavigate } from "react-router-dom";

const Section14 = ({ sections }) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
const navigate = useNavigate()
  const image = sections?.find((item) => item?.key_name === "Image")?.key_value;
  const Link = sections?.find((item) => item?.key_name === "LINK")?.key_value;
  const btnName = sections?.find((item) => item?.key_name === "ButtonName")?.key_value;
  const firstName = sections?.find((item) => item?.key_name === "TITLE")?.key_value;
  const [showContent, setShowContent] = useState(false);
  return (
    <div className={`offer_popup ${showContent ? "show" : ""}`}>
      <div className="offer_btn" onClick={() => setShowContent(!showContent)}>
        <div className="arrow" />
        <h3>{firstName}</h3>
      </div>
      <div
        className="offer_content"
        style={{
          backgroundImage: `url(${AppUrl + image})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="row">
          <div className="col-md-12 px-5 o_c_top">
            <div className="row align-items-center py-4">
              <div className="col-6" />
            </div>
            <div className="row bottom">
              <div className="col-6 siugn-up-button-section14">
                <a onClick={()=>navigate(Link)}>{btnName} &gt;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section14;
