import React from "react";
import "./section2.css";
import {
  Navigation,
  Autoplay,
  Pagination,
  FreeMode,
  Thumbs,
  Zoom,
} from "swiper/modules";
import "swiper/swiper-bundle.css";

import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useNavigateShop from "../../../hooks/useNavigateShop";

SwiperCore.use([Navigation, Autoplay, Pagination, FreeMode, Thumbs, Zoom]);
const Section2 = ({sections}) => {
  const adsData = sections?.find((item)=> item?.key_name)?.ads_data
  const Appurl = process.env.REACT_APP_IMAGE_URL;

  const { navigateShop } = useNavigateShop();

  return (
    <div className="mt-2">
      <Swiper
        loop={true}
        slidesPerView={"auto"}
        spaceBetween={0}
        navigation={false}
        modules={[FreeMode, Navigation, Thumbs]}
        className="quick-modal-swiper2 my-100 "
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
        }}
      >
        {adsData?.map((data, index) => (
          <SwiperSlide
            key={index}
            style={{
           
              height: "auto",
              cursor: "pointer",
            }}
          
          >
            <div
              className="section2-card"
              style={{
                backgroundImage: `url(${Appurl+data?.image_path})`,
              }}
              onClick={()=>navigateShop(data?.link_one)}
            >
              <div className="content p-3 px-4">
                <button className="btn btn-primary action-btn"  onClick={()=>navigateShop(data?.link_one)}>
                  Shop
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Section2;
