import React from "react";
import "./filter.css";
import { useShopContext } from "./ShopContext";
const CategoryFilter = () => {
  const { categoriesOptions, handleCategoryChange, selectedCategory ,setSelectedFilters} =
  useShopContext();
  return (
    <div
      className="sing_filter has_more_btn"
        // style={{
        //   borderBottom:
        //     categoriesOptions && categoriesOptions?.length > 0
        //       ? "2px solid #e9e9e9"
        //       : "none",
        // }}
    >
      {categoriesOptions?.length > 0 && (
      <div className="d-flex justify-content-between align-items-center mb-2 filter-header-name">
        <div className="">
        <h6 className="m-0 ">Categories</h6>
        </div>
        {/* {selectedCategory ? 
          <button
            className="clearFilterButton"
            onClick={(e) =>{
             
              handleCategoryChange(0,{})}}
          >
            Clear
          </button>:null} */}
     
      </div>
      )}
      <ul className="ultest" id="category">
        {categoriesOptions?.length > 0 &&
          categoriesOptions?.map((category) => (
            <li className="litest" key={category?.id}>
              <input
                id={category?.category_name}
                name="category"
                type="radio"
                className="shopradio"
                checked={selectedCategory === category?.id}
                onChange={(e) => handleCategoryChange(category?.id,category)}
              />
              <label htmlFor={category?.category_name}>
                {category?.category_name}
              </label>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CategoryFilter;
