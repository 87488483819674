import React from "react";
// import "./tab.css";
import "./address.css"
import { FaEdit } from "react-icons/fa";
const AddressDetails = ({
  id,
  isDefault,
  name,
  addressTag,
  addressOne,
  addressTwo,
  country,
  state,
  city,
  pincode,
  mobileNo,
  selectedId,
  handleAddressSelect,
  handleEdit
}) => {
  return (
    <div className="book-address-section">
      <div className="book-address-box">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2">
            {/* <input
              type="radio"
              checked={selectedId === id}
              onChange={() => handleAddressSelect(id)}
            /> */}
            {isDefault && (
              <p style={{ fontSize: "13px", paddingTop: "3px",marginBottom:"0px",fontWeight:"800",color:"#ff3f6c" }}>Default</p>
            )}
          </div>
          <div className="d-flex gap-2">
          <div className="" style={{cursor:"pointer"}} onClick={handleEdit}><FaEdit /></div>

          <div className="book-address-tag">{addressTag}</div>
          </div>
        </div>
        <h6 className="fw-bold pt-2">{name}</h6>
          
        <p className="mb-2 fs-8 mt-2 address-lines">
          {addressOne}
        </p>
        <p className="mb-2 fs-10 address-lines">
          {addressTwo},
        </p>
        <h6 className="mb-2" style={{ fontSize: "14px" }}>
          {country},
        </h6>
        <div
          className="mb-2 "
          style={{ color: "black !important", fontSize: "14px" }}
        >
          {state},{city}-{pincode}
        </div>
        <div className="phone">
          Mobile: <b>{mobileNo}</b>
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
