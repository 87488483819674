import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function SidebarComponent({
  isOpen,
  handleCloseSidebar,
  category,
  subCategory,
}) {
  const [openSections, setOpenSections] = useState({});
  const [openSubsections, setOpenSubsections] = useState({});
const navigate = useNavigate();
  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const toggleSubsection = (subsection) => {
    setOpenSubsections((prev) => ({
      ...prev,
      [subsection]: !prev[subsection],
    }));
  };
  const handleCategoryClick = (categoryID, category_name) => {
   
    navigate(`/shop`, {
      state: { productid: categoryID,categoryname: category_name },
    });
    handleCloseSidebar();
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={handleCloseSidebar}>
      <div className="d-flex justify-content-center">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMa2u5m9ZGIdoGcNTZqoesl5j74oZ15pgXdA&s"
          width={250}
          alt="mobile-side"
        />
      </div>
      <div style={{ padding: "16px", width: "250px" }}>
        <List component="nav" style={{ padding: 0 }}>
          {category &&
            category.length > 0 &&
            category.map((cat) => (
              <div key={cat.id}>
                <ListItem
                  button
                  onClick={() => toggleSection(cat.id)}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#FF3F6C",
                  }}
                >
                  <ListItemText primary={cat.category_name.toUpperCase()} onClick={() =>{
navigate(`/page/${cat.slug}`)
handleCloseSidebar()
                  } }/>
                  {openSections[cat.category_name] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={openSections[cat.id]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {subCategory
                      .filter((subCat) => subCat.parent_id === cat.id)
                      .map((filteredSubCat) => (
                        <div key={filteredSubCat.id}>
                          <ListItem
                            button
                            sx={{
                              paddingLeft: "16px",
                              fontSize: "16px", // Subcategory font size
                              fontWeight: "normal",
                              color: "grey", // Subcategory font weight
                            }}
                            onClick={() =>{
                              toggleSubsection(filteredSubCat.category_name)
                              handleCategoryClick(
                                filteredSubCat.id,
                                filteredSubCat.category_name
                              )
                            }
                            }
                          >
                            <ListItemText
                              sx={{}}
                              primary={filteredSubCat.category_name}
                            />
                            {openSubsections[filteredSubCat.category_name] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            sx={{
                              paddingLeft: "10px",
                            }}
                            in={openSubsections[filteredSubCat.category_name]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {subCategory
                                .filter(
                                  (subSubCat) =>
                                    subSubCat.parent_id === filteredSubCat.id
                                )
                                .map((nestedSubCat) => (
                                  <ListItem
                                    key={nestedSubCat.id}
                                    sx={{
                                      paddingLeft: "25px",
                                      color: "brown",
                                    }}
                                    onClick={()=>{
                                      handleCategoryClick(
                                        nestedSubCat.id,
                                        nestedSubCat.category_name
                                      )
                                    }}
                                  >
                                    <ListItemText
                                      primary={nestedSubCat.category_name}
                                    />
                                  </ListItem>
                                ))}
                            </List>
                          </Collapse>
                        </div>
                      ))}
                  </List>
                </Collapse>
              </div>
            ))}
        </List>
      </div>
    </Drawer>
  );
}

export default SidebarComponent;
