import React, { useEffect, useState } from "react";
import CardList from "./CardList";
import "./checkout.css";
import { FaTag } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import PriceCard from "./PriceCard";
import AddressDetails from "./AddressList";
import AppModal from "../../components/AppModal/AppModal";
import { useFormik } from "formik";
import { AddressSchema } from "./validation";
import AddressForm from "../../components/AddressForm/AddressForm";
import {
  createBillingAddressApi,
  createShippingAddressApi,
  getBillingAddress,
  getShippingddress,
} from "../../services/AddressService";
import toast from "react-hot-toast";
import "./tab.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartAmountDetials } from "../../Redux/Slice/cartSlice";
import { placeOrderCreate } from "../../services/CartService";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";

const billingInitialValues = {
  name: "",
  mobile_no: "",
  email: "",
  address_line_one: "",
  address_line_two: "",
  pincode: "",
  country_id: "",
  state_id: "",
  city_id: "",
  is_default: false,
};
const shippingInitialValues = {
  name: "",
  mobile_no: "",
  email: "",
  address_line_one: "",
  address_line_two: "",
  pincode: "",
  country_id: "",
  state_id: "",
  city_id: "",
  is_default: false,
};
const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAddressForm, setIsAddressForm] = useState(false);
  const token = localStorage.getItem("token");
  const [shippingAddress, setShippingAddress] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [activeTab, setActiveTab] = useState("description");
  const [isDisabledPlaceOrderBtn, setIsDisabledPlaceOrderBtn] = useState(false);
  const { loading, error, cartTotalAmount } = useSelector(
    (state) => state?.cartAmountDetails
  );


const[isNoCartAlert,setIsNoCartAlert]=useState(false)
const [isDisabled, setIsDisabled] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const billingAddressFormik = useFormik({
    initialValues: billingInitialValues,
    validationSchema: AddressSchema,
    onSubmit: handleBillingSubmit,
  });
  const shippingAddressFormik = useFormik({
    initialValues: shippingInitialValues,
    validationSchema: AddressSchema,
    onSubmit: handleShippingSubmit,
  });

  useEffect(() => {
    dispatch(fetchCartAmountDetials(token));
  }, []);
  useEffect(() => {
    getShippingAddressData(token);
    getBillingAddressData(token);
  }, []);

  const getShippingAddressData = async (token) => {
    try {
      const response = await getShippingddress(token);
      if (response?.status_code === 200) {
        setShippingAddress(response?.data || []);
      }
    } catch (error) {}
  };
  const getBillingAddressData = async (token) => {
    try {
      const response = await getBillingAddress(token);
      if (response?.status_code === 200) {
        setBillingAddress(response?.data || []);
      }
    } catch (error) {}
  };
  const [pincodeValidationError, setPincodeValidationError] = useState("");
  async function pincodeValidation(values) {
    let notError = false;
    try {
      const checkPinCode = Number(values.pincode);
      const validatePinCodeUrl = `https://api.postalpincode.in/pincode/${checkPinCode}`;
      const response = await fetch(validatePinCodeUrl);
      const data = await response.json();
      let resState = data[0]?.PostOffice[0]?.State?.replace(
        /\s+/g,
        ""
      )?.toLowerCase();
      let givenState = values?.state_name?.replace(/\s+/g, "")?.toLowerCase();
      let finalState = resState === givenState;

      if (data[0].Status === "Success" && finalState) {
        setPincodeValidationError("");
        return (notError = "Success");
      } else {
        setPincodeValidationError("Given Pin Code is InValid!!!");
        return;
      }
    } catch (error) {
      console.error("Error validating pincode:", error);
      setPincodeValidationError("Error validating pincode.");
      return;
    }
  }

  async function handleBillingSubmit(values) {
    setIsDisabled(true)
    pincodeValidation(values).then(async (x) => {
      if (
        Object.keys(billingAddressFormik.errors)?.length === 0 &&
        x === "Success"
      ) {
        setPincodeValidationError("");

        let billingData = {
          id: values?.id,
          billing_address_id: values?.id,
          billing_name: values?.name,
          billing_email: values?.email,
          billing_phone_no: values?.mobile_no,
          billing_address_line_one: `${values?.address_line_one}`,
          billing_address_line_two: values?.address_line_two,
          billing_pincode: values?.pincode,
          billing_set_default: values?.is_default == true ? 1 : 0,
          billing_country: values?.country,
          billing_state: values?.state,
          billing_city: values?.city,
          billing_is_active: 1,
        };
        try {
          const response = await createBillingAddressApi(billingData, token);
          if (response.status_code == 200) {
            toast.success("Billing address added successfully!");
            getBillingAddressData(token);

            //  setIsAddressForm(false);
            setActiveTab("specification");
    setIsDisabled(false)

          }
    
  } catch (error) {
    setIsDisabled(false)
  }
      }
    });
  }
  async function handleShippingSubmit(values) {
    setIsDisabled(true)

    pincodeValidation(values).then(async (x) => {
      if (
        Object.keys(shippingAddressFormik.errors)?.length === 0 &&
        x === "Success"
      ) {
        let shippingvalues = {
          id: values?.id,
          shipping_address_id: values?.id,
          shipping_name: values?.name,
          shipping_email: values?.email,
          shipping_phone_no: values?.mobile_no,
          shipping_address_line_one: `${values?.address_line_one}`,
          shipping_address_line_two: values?.address_line_two,
          shipping_pincode: values?.pincode,
          shipping_set_default: values?.is_default == true ? 1 : 0,
          shipping_country: values?.country,
          shipping_state: values?.state,
          shipping_city: values?.city,
          shipping_is_active: 1,
        };
        try {
          const response = await createShippingAddressApi(
            shippingvalues,
            token
          );
          if (response.status_code == 200) {
            toast.success("Shipping address added successfully!");
            getShippingAddressData(token);
            setIsAddressForm(false);
            setActiveTab("description");
            billingAddressFormik.resetForm();
            shippingAddressFormik.resetForm();
    setIsDisabled(false)

          }
        } catch (error) {}
      }
    });
  }

  const handleBillingShippingSame = (e) => {
    shippingAddressFormik.setFieldValue("terms_agree", e.target.checked);
    if (e.target.checked) {
      shippingAddressFormik.setValues(billingAddressFormik.values);
    } else {
      shippingAddressFormik.resetForm();
    }
  };
  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState(null);

  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState(null);

  const handleBillingAddressSelect = (id) => {
    setSelectedBillingAddressId(id);
  };

  const handleShippingAddressSelect = (id) => {
    setSelectedShippingAddressId(id);
  };
  useEffect(() => {
    let defaultBillingAddressId = billingAddress?.find(
      (x) => x?.billing_set_default === 1
    );
    let defaultShippingAddressId = shippingAddress?.find(
      (x) => x?.shipping_set_default === 1
    );

    setSelectedBillingAddressId(defaultBillingAddressId?.billing_address_id);
    setSelectedShippingAddressId(defaultShippingAddressId?.shipping_address_id);
  }, [billingAddress, shippingAddress]);

  useEffect(() => {

    if (!loading && Object.keys(cartTotalAmount)?.length === 0) {

      setIsNoCartAlert(true)
    }
  }, [cartTotalAmount]);

  const placeOrderContinue = async () => {
    if (!selectedBillingAddressId || !selectedShippingAddressId) {
      toast.warning("Please select billing and shipping address !");
      return;
    }
    setIsDisabledPlaceOrderBtn(true);
    const payload = {
      billing_address_id: selectedBillingAddressId,
      shipping_address_id: selectedShippingAddressId,
    };
    try {
      const response = await placeOrderCreate(payload, token);
      if (response.status_code === 200) {
        toast.success("Order placed successfully !");
        const orderId = response.data;
        const data = { order_id: orderId };
        const encodedData = btoa(JSON.stringify(data));
        const laravelAppUrl = process.env.REACT_APP_LARAVEL_APP_URL;
        window.location.href = `${laravelAppUrl}/manual-payment?F1!sO=${encodedData}`;
        document.getElementById("placeOrderButton").disabled = false;
        setIsDisabledPlaceOrderBtn(false);

      }
    } catch (error) {
      // document.getElementById("placeOrderButton").disabled = false;
      console.error(error);
      setIsDisabledPlaceOrderBtn(false);
    }
  };

  return (
    <div className="container mt-5 px-5">
      <div className="row justify-content-center">
        <div className="col-lg-1"></div>

        <div className="col-lg-6">
          <button
            className="btn border-0 fw-bold cart-action-btn"
            style={{ color: "rgb(255, 63, 108)" }}
            onClick={() => navigate("/checkout/cart")}
          >
            <IoArrowBackCircleSharp size={25} /> Back to cart
          </button>
          <div className="d-flex justify-content-between">
            <button className="btn border-0 fw-bold cart-action-btn">
              Select Delivery Address
            </button>
            <button
              className=" border  address-action-btn"
              onClick={() => setIsAddressForm(true)}
            >
              ADD NEW ADDRESS
            </button>
          </div>
          <h5
            style={{
              fontSize: "12px",
              color: "#535766",
              fontWeight: 700,
              margin: "30px 0px 16px 2px",
            }}
          >
            Billing and Shipping Address
          </h5>
          <div
            className="mt-3"
            style={{
              minHeight: "500px",
              overflow: "auto",
              scrollBehavior: "smooth",
            }}
          >
            <div className="d-flex  gap-3">
              {(shippingAddress?.length === 0 ||
                billingAddress?.length === 0) && (
                <p className="text-center" style={{ fontSize: "12px" }}>
                  No Address Available
                </p>
              )}
              <div>
                {billingAddress?.length > 0 &&
                  billingAddress?.map((x) => {
                    return (
                      <div className="mt-2">
                        <AddressDetails
                          id={x?.billing_address_id}
                          isDefault={
                            x?.billing_set_default === 1 ? true : false
                          }
                          name={x?.billing_name || ""}
                          addressTag={"Billing"}
                          addressOne={x?.billing_address_line_one}
                          addressTwo={x?.billing_address_line_two}
                          country={x?.billing_country}
                          state={x?.billing_state}
                          city={x?.billing_city}
                          pincode={x?.billing_pincode}
                          mobileNo={x?.billing_phone_no}
                          selectedId={selectedBillingAddressId}
                          handleAddressSelect={handleBillingAddressSelect}
                        />
                      </div>
                    );
                  })}
              </div>
              <div>
                {shippingAddress?.length > 0 &&
                  shippingAddress?.map((x) => {
                    return (
                      <div className="mt-2">
                        <AddressDetails
                          id={x?.shipping_address_id}
                          isDefault={
                            x?.shipping_set_default === 1 ? true : false
                          }
                          name={x?.shipping_name || ""}
                          addressTag={"Shipping"}
                          addressOne={x?.shipping_address_line_one}
                          addressTwo={x?.shipping_address_line_two}
                          country={x?.shipping_country}
                          state={x?.shipping_state}
                          city={x?.shipping_city}
                          pincode={x?.shipping_pincode}
                          mobileNo={x?.shipping_phone_no}
                          selectedId={selectedShippingAddressId}
                          handleAddressSelect={handleShippingAddressSelect}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-between pointer"
            style={{
              border: "1px solid #eaeaec",
              marginTop: "20px",
              padding: "5px",
            }}
            onClick={() => setIsAddressForm(true)}
          >
            <button className="btn border-0 fw-bold cart-action-btn ">
              <FaTag /> Add New Address
            </button>
            <div>
              <MdKeyboardArrowRight size={25} style={{}} />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <PriceCard
            placeOrderContinue={placeOrderContinue}
            isDisabledPlaceOrderBtn={isDisabledPlaceOrderBtn}
          />
        </div>
        <div className="col-lg-1"></div>
      </div>
      {isAddressForm && (
        <AppModal
          title="Add New Address"
          size="lg"
          show={isAddressForm}
          onHide={() => {
            setIsAddressForm(false);
            billingAddressFormik.resetForm();
            shippingAddressFormik.resetForm();
            setActiveTab("description");
          }}
          closeButton={true}
        >
          <div className="tab tab-nav-simple mt-1">
            <ul className="nav nav-tabs justify-content-center" role="tablist">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "description" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("description")}
                >
                  Billing
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "specification" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("specification")}
                >
                  Shipping
                </button>
              </li>
            </ul>
            <div className="tab-content">
              {activeTab === "description" && (
                <div className="tab-pane active in" id="product-tab-additional">
                  <AddressForm
                    activeTab={activeTab}
                    formik={billingAddressFormik}
                    pincodeValidationError={pincodeValidationError}
                    isDisabled={isDisabled}

                  />
                </div>
              )}
              {activeTab === "specification" && (
                <div className="tab-pane active in" id="product-tab-additional">
                  <AddressForm
                    activeTab={activeTab}
                    formik={shippingAddressFormik}
                    handleBillingShippingSame={handleBillingShippingSame}
                    pincodeValidationError={pincodeValidationError}
                    isDisabled={isDisabled}

                  />
                </div>
              )}
            </div>
          </div>
        </AppModal>
      )}
      {isNoCartAlert && (
        <ConfirmationDialog
          onClose={() => {}}
          handleDelete={() => navigate("/shop")}
          primaryText={"No items in the cart. Do you want to go to the shop?"}
          actiontext={"Yes, take me to the cart!"}
          isCancel={true  }
        />
      )}
    </div>
  );
};

export default Checkout;
