import { configureStore } from "@reduxjs/toolkit";
import userSettingsDataReducer from "./Slice/SettingsDataSlice";
import cartAmountReducer from "./Slice/cartSlice"
import useSearchDataReducer from "./Slice/SearchData";
import userReducer from './Slice/userDeatails'

const store = configureStore({
  reducer: {
    userSettingData: userSettingsDataReducer,
    cartAmountDetails : cartAmountReducer,

    useSearchData: useSearchDataReducer,
    userDetails: userReducer,

  },
});

export default store;
