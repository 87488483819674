import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getMainCatgoryData = async () => {
  try {
    const response = await apiService.get("/dropdown-main-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleLogout = async(token)=>{
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get('/logout', config)
    return response.data
  } catch (error) {
    throw error
  }
}