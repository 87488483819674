import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSubpageDetails } from "../../services/FooterServices";

const Pages = () => {
  const [supPageDetail, setSupPageDetail] = useState([]);
  const location = useLocation();
  const pageId = location.state.pageId;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (pageId) {
      getPageDetail(pageId);
    }
  }, [pageId]);
  const getPageDetail = async (pageId) => {
    try {
      const response = await getSubpageDetails(pageId);
      setSupPageDetail(response.data);
    } catch (error) {}
  };
  return (
    <div className="container mt-5">
      <h2 className="text-center">{supPageDetail?.page_name}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: supPageDetail?.page_content }}
      ></div>
    </div>
  );
};

export default Pages;
