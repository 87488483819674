import { useNavigate } from "react-router-dom";

const useNavigateShop = () => {
  const navigate = useNavigate();

  const navigateShop = (url) => {
    if (url) {
      const parts = url.split("/");
      const productid = parts[0];
      const brandsUrlId = parts[1];
      const minPriceUrl = parts[2];
      const maxPriceUrl = parts[3];

      navigate("/shop", {
        state: { productid, brandsUrlId, minPriceUrl, maxPriceUrl },
      });
    }
  };

  return { navigateShop };
};

export default useNavigateShop;
