import React from "react";
import useNavigateShop from "../../../hooks/useNavigateShop";

const Section4 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();

  const adsData = sections?.find((item) => item?.key_name === "ADS")?.ads_data;
  const Appurl = process.env.REACT_APP_IMAGE_URL;
  return (
    <div className="container-fluid my-3">
      <div className="row">
        {adsData?.map((item, index) => (
          <div
            key={index}
            className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"
          >
            <img
              src={Appurl + item?.image_path}
              alt={item?.name}
              className="img-fluid w-100"
              onClick={()=>navigateShop(item?.link_one)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section4;
