import axios  from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const userCartList = (token) => {
    return apiService.get('/get-user-cart', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // Remove cart API

export const removeCartApi = async (token, payload) => {
    try {
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await apiService.post(
          "/remove-add-to-cart-product",
          payload,
          config
        );
        return response.data;
      } else {
        return {
          status: 401,
          message: "Error",
        };
      }
    } catch (error) {
      return 403;
    }
  };
  // Updated Cart API
export const updateCartApi = async (token, payload) => {
    try {
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await apiService.post(
          "/update-to-cart",
          payload,
          config
        );
        return response.data;
      } else {
        return {
          status: 401,
          message: "Please Login First",
        };
      }
    } catch (error) {
      return 403;
    }
  };

  export const userWishList = async (token) => {
    if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await apiService.get("/get-user-whishlist", config);
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error("Token not provided");
    }
  };
  export const removeWishList = async (id, token) => {
    try {
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const body = {
          product_id: id,
        };
        const response = await apiService.post("/remove-wishlist", body, config);
        return response.data;
      } else {
        return {
          status: 401,
          message: "Please Login First",
        };
      }
    } catch (error) {
      return 403;
    }
  };
  export const getUserCartTotalDetailsApi = async (token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiService.get("/get-cart-total", config);
      return response.data;
    } catch (error) {}
  };
  export const applyCoupon = async(token,payload)=>{
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await apiService.post('/apply-coupon',payload, config)
      return response.data
    } catch (error) {
      throw error
    }
  }

  export const placeOrderCreate = async (payload,token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiService.post("/place-order", payload, config);
      return response.data;
    } catch (error) {
      console.error("Order cannot be placed :( ");
    }
  };