import React from "react";

import "./ExtraSection3.css";
import { Accordion } from "react-bootstrap";
import { APP_URL } from "../../../shared/constants";

const ExtraSection3 = ({ sections }) => {

  function consolidatedData() {
    let res = {
      text_1: sections?.find((x) => x?.key_name === "text_1")?.key_value,
      Discription_1: sections?.find((x) => x?.key_name === "Discription_1")
        ?.key_value,
      text_2: sections?.find((x) => x?.key_name === "text_2")?.key_value,
      Discription_2: sections?.find((x) => x?.key_name === "Discription_2")
        ?.key_value,
      text_3: sections?.find((x) => x?.key_name === "text_3")?.key_value,
      Discription_3: sections?.find((x) => x?.key_name === "Discription_3")
        ?.key_value,
      text_4: sections?.find((x) => x?.key_name === "text_4")?.key_value,
      Discription_4: sections?.find((x) => x?.key_name === "Discription_4")
        ?.key_value,
      img: sections?.find((x) => x?.key_name === "Image")?.key_value,
      text_5: sections?.find((x) => x?.key_name === "text_5")?.key_value,
      Discription_5: sections?.find((x) => x?.key_name === "Discription_5")
        ?.key_value,
      btnName: sections?.find((x) => x?.key_name === "BTN_NAME")?.key_value,
      link: sections?.find((x) => x?.key_name === "LINK")?.key_value,
    };

    return res;
  }
  return (
    <div className="container-fluid ExtraSection3_section">
      <div className="row align-items-center">
        <div className="col-lg-4 ">
          <div className="row justify-content-center ">
            <div className="col-lg-11">
              <Accordion defaultActiveKey="0" className="border-0">
                {/* First Accordion Item */}
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accordion-header-hover">
                    <span className="me-1">01</span>
                    {consolidatedData()?.text_1}
                  </Accordion.Header>
                  <Accordion.Body>
                    {consolidatedData()?.Discription_1}
                  </Accordion.Body>
                </Accordion.Item>

                {/* Second Accordion Item */}
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="accordion-header-hover">
                    <span className="me-1">02</span>
                    {consolidatedData()?.text_2}
                  </Accordion.Header>
                  <Accordion.Body>
                    {consolidatedData()?.Discription_2}
                  </Accordion.Body>
                </Accordion.Item>

                {/* Third Accordion Item */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="accordion-header-hover">
                    <span className="me-1">03</span>
                    {consolidatedData()?.text_3}
                  </Accordion.Header>
                  <Accordion.Body>
                    {consolidatedData()?.Discription_3}
                  </Accordion.Body>
                </Accordion.Item>

                {/* Fourth Accordion Item */}
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="accordion-header-hover">
                    <span className="me-1">04</span>
                    {consolidatedData()?.text_4}
                  </Accordion.Header>
                  <Accordion.Body>
                    {consolidatedData()?.Discription_4}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="img-hover-effect">
            <img
              src={APP_URL + consolidatedData()?.img}
              alt="img"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-lg-4 my-5 ExtraSection3_section-title">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2>{consolidatedData()?.text_5}</h2>
              <p>{consolidatedData()?.Discription_5}</p>
              <div className="d-flex justify-content-center">
                <a
                  href={consolidatedData()?.link}
                  className="ExtraSection3_section-btn justify-items-center"
                >
                  {consolidatedData()?.btnName}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraSection3;
