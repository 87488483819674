import React from "react";
import { useNavigate } from "react-router-dom";

const EmptyCard = () => {
  const navigate = useNavigate();

  return (
    <div className="text-center my-5">
      <h5>YOUR WISHLIST IS EMPTY</h5>
      <button
        className="btn btn-primary my-3"
        onClick={() => navigate("/shop")}
      >
        CONTINUE SHOPPING
      </button>
    </div>
  );
};

export default EmptyCard;
