import './pagenotFound.css'
import RoboImg from  '../../images/robo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Appurl = process.env.REACT_APP_IMAGE_URL
function PageNotFound() {
    const settingsData = useSelector(
        (state) => state?.userSettingData?.settingData
      );


      const logo =  settingsData?.find(el=> el?.key_name === "SITE_LOGO" )

    const location = useLocation()
    const navigate =  useNavigate()

  return (

    <div className='page-not__found'>

        <div className='page-not-found_container'>
            <div className='page-not-found-content'>
                <div className='page-not-found-logo'>
                    <img src={`${Appurl}${logo.key_value}`}alt="Logo"/>
                </div>

                <div className='page_content-code'>
                    <strong>404.</strong>
                    <span>That's an error.</span>
                </div>
                <div className='page_conten-url'>
                    <p>The requested URL {location.pathname} was not found on this server. <span>That's all we know</span></p>
                </div>
                <button className='page-not-found-btn' onClick={() => navigate('/shop')}>Go back to shop page</button>

            </div>
            <div className='page-not-found-image'>
                <img src={RoboImg} alt="" />
            </div>
        </div>
    </div>

  )
}

export default PageNotFound
