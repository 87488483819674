import { createContext, useContext, useEffect, useState } from "react";
import {
  applyCoupon,
  removeCartApi,
  updateCartApi,
  userCartList,
} from "../../services/CartService";
import toast from "react-hot-toast";
import { fetchCartAmountDetials } from "../../Redux/Slice/cartSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { CoupenSchema } from "./validations";
import { fetchUserData } from "../../Redux/Slice/userDeatails";
const initialState = {
  coupon_code: "",
};
const CheckoutContext = createContext(null);

function CheckoutProvider({ children }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [isCouponForm, setIsCouponForm] = useState(false);
  const [coupenLoading, setCoupenLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cartListData, setCartListData] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [loadingIds, setLoadingIds] = useState([]);
  const [isConfirmDeleteCart, setIsConfirmDeleteCart] = useState(false);
  const [productID, setProductID] = useState(null);
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CoupenSchema,
    onSubmit: handleFormSubmit,
  });
  useEffect(() => {
    dispatch(fetchCartAmountDetials(token));
  }, []);
  useEffect(() => {
    fetchCartList();
  }, []);

  const fetchCartList = async () => {
    setIsLoading(true);
    try {
      const response = await userCartList(token);
      if (response.data) {
        setCartListData(response?.data?.data);
      }
    } catch (error) {
      console.error("Failed to fetch wishlist:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const updateCart = async (data, count) => {
    let payload = {
      product_id: data?.product_id,
      quantity: count,
    };

    const addToCartResponse = await updateCartApi(token, payload);
    if (addToCartResponse.status_code === 200) {
      toast.success(addToCartResponse?.message);
      fetchCartList();
      dispatch(fetchCartAmountDetials(token));
    } else if (addToCartResponse === 403) {
      toast.error("Error");
    } else {
      toast.error(" Please login.");
    }
  };
  async function handleFormSubmit(values) {
    setCoupenLoading(true);

    try {
      const response = await applyCoupon(token, values);
      if (response?.status_code === 200) {
        dispatch(fetchCartAmountDetials(token));
        toast.success("Coupon apply successfully");

        setCoupenLoading(false);
        formik.resetForm();
      }
    } catch (error) {
      toast.error(error?.response?.data?.data?.error);
      setCoupenLoading(false);
    }
  }
  const cartRemove = async (id) => {
    setLoadingIds((prev) => [...prev, id]);
    try {
      let payload = {
        product_id: id,
      };
      const response = await removeCartApi(token, payload);
      if (response?.status_code === 200) {
        setCartListData((prev) =>
          prev?.filter((item) => item.product_id !== id)
        );
        dispatch(fetchUserData(token));
        dispatch(fetchCartAmountDetials(token));
        toast.success("Cart deleted Successfully");
        setIsConfirmDeleteCart(false);
        // getUserCartTotalCount();
      }
    } catch (error) {
      console.error("Failed to remove item from wishlist:", error);
      setIsConfirmDeleteCart(false);
    } finally {
      setLoadingIds((prev) => prev.filter((itemId) => itemId !== id));
      setIsConfirmDeleteCart(false);
    }
  };
  return (
    <CheckoutContext.Provider
      value={{
        isCouponForm,
        setIsCouponForm,
        coupenLoading,
        setCoupenLoading,
        isLoading,
        cartListData,
        quantity,
        setQuantity,
        updateCart,
        formik,
        loadingIds,
        isConfirmDeleteCart,
        setIsConfirmDeleteCart,
        productID,
        setProductID,
        setLoadingIds,cartRemove
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}

function useCheckoutContext() {
  try {
    return useContext(CheckoutContext);
  } catch (error) {
    console.error("Error using ViewProductContext:", error);
    return null;
  }
}

export { CheckoutContext, CheckoutProvider, useCheckoutContext };
