import { REQUIRED } from "../../shared/constants";
import * as yup from "yup";

export const AddressSchema = yup.object().shape({
    name: yup.string().required(REQUIRED),
  
    email: yup
      .string()
      .email("Invalid email")
      .required(REQUIRED)
      .test("Validate Email", "Valid Email", (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }),
      address_line_one:  yup.string().required(REQUIRED),
      address_line_two :  yup.string().required(REQUIRED),
      mobile_no: yup.number()
      .typeError("Phone number must be a number") 
      .positive("Phone number cannot be negative")  
      .integer("Phone number must be an integer")  
      .min(1000000000, "Phone number must be exactly 10 digits") 
      .max(9999999999, "Phone number must be exactly 10 digits") 
      .required("Required"),
      pincode: yup.number()
      .typeError("zipcode must be a number")
      .positive("zipcode cannot be negative")
      .integer("zipcode must be an integer")
      .min(100000, "zipcode must be exactly 6 digits") // Minimum 6 digits
      .max(999999, "zipcode must be exactly 6 digits") // Maximum 6 digits
      .required("Required"),
    
      country_id: yup.string().required("Country is required"),
      state_id: yup.string().required("State is required"),
    //   city_id: yup.string().required("City is required"),
  });