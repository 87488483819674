import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  cancelOrder,
  getSingleOrderDetail,
  orderReturnProduct,
} from "../../../services/orderServices";
import { useSelector } from "react-redux";
import { APP_URL } from "../../../shared/constants";
import { downloadInvoice } from "./downloadInvoice";
import { IoMdDownload } from "react-icons/io";

const OrderView = () => {
  const [singleOrderDetail, setSingleOrderDetail] = useState([]);
  const token = localStorage.getItem("token");
  const [orederReturnState, setOrederReturnState] = useState(true);
  const [cancelOrederState, setCancelOrederState] = useState(true);
  const location = useLocation();
  const orderId = location.state?.orderId;
  const navigate = useNavigate();
  useEffect(() => {
    if (orderId) {
      getOrderDetail(orderId);
    }
  }, [orderId]);
  const getOrderDetail = async () => {
    try {
      const response = await getSingleOrderDetail(orderId, token);
      setSingleOrderDetail(response.data);
    } catch (error) {}
  };
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  const gstNumber = settingsData?.find(
    (item) => item?.key_name === "GST_NO"
  )?.key_value;
  const logo = settingsData?.find((item) => item.key_name === "SITE_LOGO")?.key_value;

  const handleDownloadInvoice = (orderId) => {
    const appImgae = APP_URL + logo;
    downloadInvoice(orderId, appImgae);
  };
  return (
    <main className="container-fluid p-0 ">
      <div
        className="container  "
       
      >
        <div className="card">
          <header className="text-center mb-4">
            <h2 className="fw-bold text-decoration-underline">
              ORDER-DETAILS-PAGE
            </h2>
          </header>
          <div className="mb-4">
            <table className="table">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <h4 className="fw-bold">Tax Invoice</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-50">
                    {gstNumber && (
                      <div>
                        <strong>GSTIN Number:</strong> {gstNumber}
                      </div>
                    )}
                    <div>
                      <strong>Order Number:</strong>{" "}
                      {singleOrderDetail?.order_code}
                    </div>
                    <div>
                      <strong>Mode of Payment:</strong> Online
                    </div>
                    <div>
                      <strong>Order Status:</strong>{" "}
                      {singleOrderDetail?.order_status}
                    </div>
                    <div>
                      <strong>Name:</strong> {singleOrderDetail?.customer_name}
                    </div>
                    <div>
                      <strong>Email:</strong>{" "}
                      {singleOrderDetail?.customer_email}
                    </div>
                  </td>
                  <td className="text-end w-50">
                    <div>
                      <strong>Invoice Date:</strong>
                    </div>
                    <div>
                      <strong>Order Date:</strong>{" "}
                      {singleOrderDetail?.order_date}
                    </div>
                    <div>
                      <strong>Place of Supply:</strong>
                    </div>
                    <div>
                      <strong>Place of Delivery:</strong>
                      
                      {/* {singleOrderDetail?.shipping_address?.shipping_state} */}

                    </div>
                    <div>
                      <strong>Payment Status:</strong>{" "}
                      {singleOrderDetail?.payment_status}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-4">
            <table className="table">
              <tbody>
                <tr>
                  <td className="w-50">
                    <h4 className="fw-bold">Shipping Address</h4>
                    <div>
                      {singleOrderDetail?.shipping_address?.shipping_name}
                    </div>
                    <div>
                      {
                        singleOrderDetail?.shipping_address
                          ?.shipping_address_line_one
                      }
                    </div>
                    <div>
                      {singleOrderDetail?.shipping_address?.shipping_pincode}
                    </div>
                    <div>
                      Email:{" "}
                      {singleOrderDetail?.shipping_address?.shipping_email}
                    </div>
                    <div>
                      Phone:{" "}
                      {singleOrderDetail?.shipping_address?.shipping_phone_no}
                    </div>
                  </td>
                  <td className="text-end w-50">
                    <h4 className="fw-bold">Billing Address</h4>
                    <div>
                      {singleOrderDetail?.billing_address?.billing_name}
                    </div>
                    <div>
                      {
                        singleOrderDetail?.billing_address
                          ?.billing_address_line_one
                      }
                    </div>
                    <div>
                      Pincode:{" "}
                      {singleOrderDetail?.billing_address?.billing_pincode}
                    </div>
                    <div>
                      Phone:{" "}
                      {singleOrderDetail?.billing_address?.billing_phone_no}
                    </div>
                    <div>
                      Email: {singleOrderDetail?.billing_address?.billing_email}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-4">
            <table className="table table-bordered">
              <thead className="table-light">
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Discount Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {singleOrderDetail?.purchase_list?.map((item) => (
                  <tr>
                    <td>{item?.product_name}</td>
                    <td>{item?.quantity}</td>
                    <td>₹{item?.price}</td>
                    <td>₹{item?.discount_price?.toFixed(2)}</td>
                    <td>₹{item?.sales_amount?.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                
                <tr>
                  <th colSpan={4}>TOTAL AMOUNT</th>
                  <th>₹{singleOrderDetail?.total_amount?.toFixed(2)}</th>
                </tr>
                
              </tfoot>
            </table>
          </div>
          <div className="d-flex justify-content-evenly mb-2">
            <div className="d-flex justify-content-end ">
              <button
                className="btn btn-outline-primary m-b15 btnhover20"
                style={{ width: "150px" }}
                onClick={()=>handleDownloadInvoice(singleOrderDetail?.order_id)}
              >
               <IoMdDownload /> <strong>Invoice</strong>
              </button>
            </div>

            <div className="d-flex justify-content-end m-b15">
              {singleOrderDetail.order_status === "delivered" &&
              singleOrderDetail.payment_status === "paid" &&
              orederReturnState ? (
                <div className="d-flex justify-content-end m-b15">
                  <button
                    className="btn btn-outline-primary m-b15 btnhover20"
                    onClick={() => {
                      orderReturnProduct(singleOrderDetail.order_code, token);
                      setOrederReturnState(false);
                    }}
                  >
                    <strong>RETURN REQUEST</strong>
                  </button>
                </div>
              ) : (
                (singleOrderDetail.order_status === "pending" ||
                  singleOrderDetail.order_status === "processing" ||
                  singleOrderDetail.order_status === "shipping") &&
                singleOrderDetail.order_status !== "cancelled" &&
                cancelOrederState && (
                  <div className="d-flex justify-content-end m-b15">
                    <button
                      className="btn btn-outline-danger m-b15 btnhover20"
                      onClick={() =>{

                        cancelOrder(singleOrderDetail.order_code, token)
                        setCancelOrederState(false)
                        navigate("/dashboard/order-details")
                      }
                      }
                    >
                      <strong>ORDER CANCEL</strong>
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OrderView;
