import React from "react";

import { Link } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";

import "./pagination.css";
const PaginationIndex = ({
  totalProducts,
  productsPerPage,
  currentPage,
  handlePageChange,
  handleNextPage,
  handlePreviousPage,
  gotoFirstPage,
}) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="row pgntn_par">
      <div className="d-flex align-items-center  flex-wrap justify-content-center">
        <div
          className="d-flex align-items-center me-3 "
          role="button"
          onClick={gotoFirstPage}
        >
          <MdOutlineKeyboardDoubleArrowLeft style={{ color: "#A8A8A8" }} />
          <span className="page-1 ps-2">page 1</span>
        </div>
        <div className="page_number">
          <Link className="prev" onClick={handlePreviousPage}>
            <FaChevronLeft style={{ color: "#666666" }} />
            <span className="pre ps-2">Previous</span>
          </Link>
          <div>
            <div className="page_count">
              {/* Page {currentPage} of {totalPages} */}
              Page {currentPage + 1} of {totalPages}
            </div>
          </div>
          <Link className="next" onClick={handleNextPage}>
            <span className="next pe-2">Next</span>
            <FaChevronRight style={{ color: "#666666" }} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaginationIndex;
