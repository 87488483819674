import React, { useState } from "react";
import Card from "./Card";
import {
  Navigation,
  FreeMode,
  Thumbs,
} from "swiper/modules";
import "swiper/swiper-bundle.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { getPreOrderList } from "../../../services/orderServices";
import { APP_URL } from "../../../shared/constants";
const Section13 = ({sections}) => {
 let preOrderData = sections?.find((x)=>x?.key_name === "PRE_ORDER")?.pre_order_products
  return (
    <div>
      <h5 className="text-center title">Flash deals</h5>

      <div className="container-fluid mt-4">
        <Swiper
          loop={true}
          //   slidesPerView={"auto"}
          spaceBetween={20}
          navigation={false}
          modules={[FreeMode, Navigation, Thumbs]}
          className="quick-modal-swiper2"
        //   autoplay={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
        >
          {preOrderData?.length >0 && preOrderData?.map((data, index) => (
            <SwiperSlide
              key={index}
              style={{
               
                height: "auto",
                cursor: "pointer",
              }}
              //   onClick={() => handleThumbnailClick(index)}
            >
              <Card
                productImg={
                 APP_URL+  data?.thumnail_image 
                }
                brandName={data?.brand_name || ""}
                productDes={data?.product_name || ""}
                originalPrice={data?.original_price || "0"}
                discountPrice={data?.discount_price || "0"}
                productDetails={data}
                handleWishlist={() => {}}
                handleViewProduct={() => {}}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Section13;
