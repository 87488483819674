import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchData: "",
};
const useSearchData = createSlice({
  name: "userSearchData",
  initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
  },
});

export const { setSearchData } = useSearchData.actions;
export default useSearchData.reducer;
