import React, { useEffect, useState } from "react";
import "../css/header.css";
import NormalStyledLink from "./NormalStyledLink";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CategoryMainDropdown,
  CategorySubDropdown,
} from "../services/apiServices";
import { useNavigate } from "react-router-dom";
import HeaderIcons from "./HeaderIcons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../Redux/Slice/userDeatails";
import { fetchSettingsData } from "../Redux/Slice/SettingsDataSlice";
import { IconButton, useMediaQuery } from "@mui/material";
import SidebarComponent from "./SidebarComponent";
import { setSearchData } from "../Redux/Slice/SearchData";

const Header = () => {
  const token = localStorage.getItem("token");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { isSearchCall, searchData } = useSelector(
    (state) => state?.useSearchData
  );
  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  const siteLogo = settingsData?.find(
    (item) => item?.key_name === "SITE_LOGO"
  )?.key_value;
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 1793px)");
  const isSmallHederItems = useMediaQuery("(max-width: 1259px)");

  const fetchCategories = async () => {
    const responseCategory = await CategoryMainDropdown();
    setCategory(responseCategory.data);

    const responseSubCategory = await CategorySubDropdown();
    setSubCategory(responseSubCategory.data);
  };

  useEffect(() => {
    dispatch(fetchSettingsData());
    fetchCategories();
  }, []);
  useEffect(() => {
    if (token) {
      dispatch(fetchUserData(token));
    }
  }, [token]);

  const handleCategoryClick = (categoryID, category_name) => {
    // navigate(`/shop`, {
    //   state: { categoryid: categoryID, categoryname: category_name },
    // });
    navigate(`/shop`, {
      state: { productid: categoryID,categoryname: category_name },
    });
  };

  const searchProductsOnchange = async (e) => {
    let searchTerm = e.target.value;
    navigate("/shop", { state: { searchTerm } });
dispatch(setSearchData(e.target.value))
    // if (searchTerm !== "") {
    //   try {
    //     const response = await getGlobalSearchAPICall(searchTerm);
    //     const status = response.status_code;
    //     if (status === 200) {
    //       const searchData = response.data || null;
    //       navigate("/shop", { state: { searchData } });
    //     }
    //   } catch (error) {
    //     console.log("getGlobalSearchAPICall", error);
    //   }
    // }
  };

  return (
    <>
      <header className="header">
        <nav aria-label="Main Navigation" className="Main_Navigation">
          {isSmallHederItems && (
            <div
              className="sidebar-container"
              style={{
                display: "flex",
                margin: "10px",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleOpenSidebar}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <SidebarComponent
            isOpen={isSidebarOpen}
            handleCloseSidebar={() => setSidebarOpen(false)}
            category={category}
            subCategory={subCategory}
          />
          <img
            onClick={() => navigate("/")}
            src={siteLogo ? AppUrl + siteLogo : null}
            alt="logo"
            width={110}
          />
          {!isSmallHederItems && (
            <ul className="nav-list">
              {category &&
                category.length > 0 &&
                category.map((cat) => (
                  <li
                    key={cat.id}
                    className="nav-item"
                    onMouseEnter={() => setHoveredItem(cat)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <span onClick={() => navigate(`/page/${cat.slug}`)}>
                      {cat.category_name}
                    </span>
                    {hoveredItem === cat && (
                      <div
                        className="dropdown-css"
                        onMouseEnter={() => setHoveredItem(cat)}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        {subCategory
                          .filter((subCat) => subCat.parent_id === cat.id)
                          .map((filteredSubCat) => (
                            <div
                              className="dropdown-column"
                              key={filteredSubCat.id}
                            >
                              <h4
                                className="category-title"
                                onClick={() =>
                                  handleCategoryClick(
                                    filteredSubCat.id,
                                    filteredSubCat.category_name
                                  )
                                }
                              >
                                {filteredSubCat.category_name}
                              </h4>

                              <ul className="child-list">
                                {subCategory
                                  .filter(
                                    (subSubCat) =>
                                      subSubCat.parent_id === filteredSubCat.id
                                  )
                                  .map((nestedSubCat) => (
                                    <li
                                      className="child-item"
                                      key={nestedSubCat.id}
                                      onClick={() =>
                                        handleCategoryClick(
                                          nestedSubCat.id,
                                          nestedSubCat.category_name
                                        )
                                      }
                                    >
                                      {nestedSubCat.category_name}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          ))}
                      </div>
                    )}
                  </li>
                ))}
              <NormalStyledLink to="/shop">Shop</NormalStyledLink>
              <NormalStyledLink to={`/page/${"explore"}`}>
                Explore
              </NormalStyledLink>
              <span
                style={{
                  cursor: "pointer",
                  color: "#0d0d0e",
                  fontSize: "small",
                  fontWeight: "bold",
                  textDecoration: "none",
                  textTransform: "uppercase",
                }}
                onClick={() =>
                  navigate("/sup-page", {
                    state: { pageId: 7 },
                  })
                }
              >
                About Us
              </span>
            </ul>
          )}
        </nav>

        <div className="search-container">
          {!isSmallScreen && (
            <input
              type="text"
              className="search-box"
              placeholder="Find your perfect match... Search for products..."
              value={searchData || ""}
              onChange={searchProductsOnchange}
            />
          )}
          <HeaderIcons />
        </div>
        <br />
      </header>

      {isSmallScreen && (
        <div className="mobile-search-bar">
          <input
              value={searchData || ""}
            onChange={searchProductsOnchange}
            type="text"
            className="mobile-search-box"
             placeholder="Find your perfect match... Search for products..."
          />
        </div>
      )}
    </>
  );
};

export default Header;
