import React from "react";

import { useCheckoutContext } from "./CheckoutContext";
import "./coupon.css"


const CouponForm = () => {
    const{coupenLoading,formik}=useCheckoutContext();

  return (
    <form
      action=""
      onSubmit={formik.handleSubmit}
      className="checkout-coupon-input-container p-3"
    >
      <input
        type="text"
        placeholder="Enter coupon code here..."
        value={formik.values.coupon_code}
        onChange={formik.handleChange}
        name="coupon_code"
        id="coupon_code"
        className={` ${
          formik.errors.coupon_code && formik.touched.coupon_code
            ? "is-invalid"
            : ""
        } w-100 p-3`}
      />
      {formik.errors.coupon_code && formik.touched.coupon_code && (
        <div className="invalid-feedback">{formik.errors.coupon_code}</div>
      )}
      <br />
      <button className="coupon-btn w-100 p-3" type="submit">
        {coupenLoading ? "Loading.." : "Apply Coupon"}
      </button>
    </form>
  );
};

export default CouponForm;
