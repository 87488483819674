import React from "react";
import "./section5.css";
import Card from "../../../components/Card";
import { addToWishlist } from "../../../services/SectionService";
import toast from "react-hot-toast";

const Section5 = ({ sections }) => {
  const token = localStorage.getItem("token");
  const Appurl = process.env.REACT_APP_IMAGE_URL;
  const title = sections?.find((item) => item.key_name === "Title")?.key_value;
  const classficationData = sections?.find(
    (item) => item.key_name === "CLASSIFI"
  )?.classification_data?.[0]?.classifications_data;
 
  return (
    <div className="section5-wrapper">
      <h5 className="text-center title">{title}</h5>

      <div className="container-fluid mt-4">
        <div className="row">
          {classficationData?.map((item, index) => (
            <div
              key={item}
              className=" col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-4 mb-4"
            >
              <Card

                height="350px"
                product={item}
                // handleAddtoCart={()=>handleAddtoCart(+item?.product_id)}
                // item={item}
                rating={true}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section5;
