import React from "react";
import { APP_URL } from "../../../shared/constants";
import useNavigateShop from "../../../hooks/useNavigateShop";

const Section6 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();

  function consolidatedData() {
    if (sections?.length > 0) {
      let ads = sections?.find((x) => x?.key_name === "ADS")?.ads_data;
      return ads;
    }
  }
  function filterData() {
    if (consolidatedData()?.length === 8) {
      return [...consolidatedData()?.slice(0, 6)];
    } else {
      return consolidatedData();
    }
  }
  let data = [
    {},
    {},
    {
      img: consolidatedData()[6]?.image_path,
      link_one: consolidatedData()[6]?.link_one,
    },
    {
      img: consolidatedData()[7]?.image_path,
      link_one: consolidatedData()[7]?.link_one,
    },
    {},
    {},
  ];
  return (
    <div className="container-fluid my-3">
      <div className="row">
        {filterData()?.map((item) => (
          <div className="col-6 col-sm-4 col-md-3 col-lg-2 mt-4" key={item?.id}>
            <img
              src={APP_URL + item?.image_path}
              alt={item?.name}
              className="img-fluid"
              onClick={() => navigateShop(item?.link_one)}
            />
          </div>
        ))}
      </div>
      {consolidatedData()?.length === 8 && (
        <div className="row">
          {data?.length > 0 &&
            data?.map((item) => (
              <div
                className="col-6 col-sm-4 col-md-3 col-lg-2 mt-4"
                key={item?.id}
              >
                {item?.img && (
                  <img
                    src={APP_URL + item?.img}
                    alt={item?.name}
                    className="img-fluid"
                    onClick={() => navigateShop(item?.link_one)}
                  />
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Section6;
