import React from 'react';
import { Outlet } from 'react-router-dom';
import OrderSideBar from '../OrderSideBar';

const Dashboard = () => {
  return (
    <div className="container mt-5   vh-100" style={{overflowY:'auto', scrollbarWidth:'thin'}}>
      <div className="row h-100">
        <div className="col-12 col-md-4 col-xl-3 ">
          <OrderSideBar />
        </div>
        <div className="col-12 col-md-8 col-xl-9 mt-3 mt-md-0 p-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
