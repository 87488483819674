import React from "react";
import AppModal from "../AppModal/AppModal";
import { RiErrorWarningLine } from "react-icons/ri";
import "./confirm.css";
const ConfirmationDialog = ({ onClose,handleDelete,primaryText,actiontext="",isCancel=false }) => {
  return (
    <AppModal
      title=""
      size="md    "
      show={true}
      closeButton={!isCancel}
      onHide={() => {
        onClose();
      }}
    >
      <div style={{ textAlign: "center" }}>
      {!isCancel &&  <RiErrorWarningLine
          style={{ fontSize: "5rem" }}
          className="del-icon-color"
        />}
        <h5 className="pt-2 " style={{ fontSize: "1rem" }}>
          {primaryText}
        </h5>
        <div className="d-flex justify-content-center gap-2 mt-3">
          <button className="btn btn-primary" onClick={handleDelete}>{actiontext}</button>
          {!isCancel && 
          <button className="btn bg-danger text-white" onClick={onClose}>
            Cancel
          </button>}
        </div>
      </div>
    </AppModal>
  );
};

export default ConfirmationDialog;
