import React from "react";
import { Link } from "react-router-dom";

const NormalStyledLink = ({ to, children }) => {
  return (
    <Link
      to={to}
      style={{
        cursor: "pointer",
        color: "#0d0d0e",
        fontSize: "small",
        fontWeight: "bold",
        textDecoration: "none",
        textTransform: "uppercase",
      }}
    >
      {children}
    </Link>
  );
};

export default NormalStyledLink;
