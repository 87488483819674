import React, { useState } from "react";
import CardList from "./CardList";
import "./checkout.css";
import { FaTag } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import PriceCard from "./PriceCard";
import AppModal from "../../components/AppModal/AppModal";
import { useCheckoutContext } from "./CheckoutContext";
import CouponForm from "./CouponForm";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { startTransition } from "react";

const CheckoutDetails = () => {
  const {
    isCouponForm,
    setIsCouponForm,
    isConfirmDeleteCart,
    setIsConfirmDeleteCart,
    cartRemove,
    productID,
    formik,
  } = useCheckoutContext();
  const navigate = useNavigate();
  return (
    <div className="container mt-5 px-5">
      <div className="row justify-content-center">
        <div className="col-lg-1"></div>

        <div className="col-lg-6">
          <div className="d-flex justify-content-between">
            <button className="btn border-0 fw-bold cart-action-btn">
              CART
            </button>
            {/* <button className="btn border-0 fw-bold cart-action-btn">
            MOVE TO WISHLIST
          </button> */}
          </div>
          <div className="mt-3">
            <CardList />
          </div>
          <div
            className="d-flex justify-content-between pointer"
            style={{
              border: "1px solid #eaeaec",
              marginTop: "20px",
              padding: "5px",
            }}
            onClick={() =>
              startTransition(() => {
                navigate("/wishlist");
              })
            }
          >
            <button className="btn border-0 fw-bold cart-action-btn ">
              <FaTag /> Add more from Wishlist
            </button>
            <div>
              <MdKeyboardArrowRight size={25} style={{}} />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <PriceCard />
        </div>
        <div className="col-lg-1"></div>
      </div>
      {isCouponForm && (
        <AppModal
          show={isCouponForm}
          onHide={() => {
            formik.resetForm();
            setIsCouponForm(false);
          }}
          title=" APPLY COUPON"
          size={"md"}
          closeButton={true}
        >
          <CouponForm />
        </AppModal>
      )}

      {isConfirmDeleteCart && (
        <ConfirmationDialog
          onClose={() => setIsConfirmDeleteCart(false)}
          handleDelete={() => cartRemove(productID)}
          primaryText={"Want to delete the item?"}
          actiontext={"Yes,delete it!"}
        />
      )}
    </div>
  );
};

export default CheckoutDetails;
