import React from "react";
import useNavigateShop from "../../../hooks/useNavigateShop";


const Section1 = ({sections}) => {
  const adsData = sections?.find((item)=>item?.key_name ==="ADS")?.ads_data
  const Appurl = process.env.REACT_APP_IMAGE_URL
  const { navigateShop } = useNavigateShop();
  return (
    <div className="container-fluid p-0 my-3">
      <img
        src={Appurl+adsData?.[0].image_path}
        alt=""
        className="img-fluid w-100"
        onClick={()=> navigateShop(adsData?.[0]?.link_one)}
      />
    </div>
  );
};

export default Section1;
