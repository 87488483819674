import { REQUIRED } from "../../../shared/constants";
import * as yup from "yup";
const validateEmail = (email) => {
    if (!email) {
      return "Please provide an email address.";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please provide a valid email address.";
    }
    if (/[^a-zA-Z0-9@._-]/g.test(email)) {
      return "Email contains special characters. Please remove them.";
    }
    return "";
  };
export const SubscribeSchema = yup.object().shape({
    email: yup
      .string()
      .required(REQUIRED)
      .test("validateEmail", "Invalid email", function (value) {
        const errorMessage = validateEmail(value);
        if (errorMessage) {
          return this.createError({ message: errorMessage });
        }
        return true;
      }),
  
    
  });