
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { contactUsSubmit } from '../../services/apiServices';
import './contact.css';
import { useSelector } from 'react-redux';


function ContactUs() {

  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );


  const location = settingsData?.find(el => el?.key_name === "LOCATE_MAP_LINK")
  console.log(location)
  console.log(settingsData)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_no: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Name is required.';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email format.';
    }
    if (!formData.phone_no.trim()) newErrors.phone_no = 'Phone number is required.';
    if (!formData.message.trim()) newErrors.message = 'Message is required.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' })); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      toast.error("Please fill all the fields.");
      return;
    }
console.log(formData)
    try {
      const res = await contactUsSubmit(formData);
      if (res.status_code === 200) {
        toast.success("Contacted successfully");
        setFormData({
          name: '',
          email: '',
          phone_no: '',
          message: ''
        }); 
      }
    } catch (error) {
      toast.error("An error occurred while submitting. Please try again.");
    }
  };

  return (
    <div className='contact-us-container'>
      <div className='contact-sub-container'>
        <form onSubmit={handleSubmit}>

          <div>

          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            />
          {errors.name && <small className="error-text">{errors.name}</small>}
            </div>

          <div>

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            />
          {errors.email && <small className="error-text">{errors.email}</small>}
            </div>


<div>

          <input
            type="text"
            name="phone_no"
            placeholder="Phone"
            value={formData.phone_no}
            onChange={handleChange}
            />
          {errors.phone_no && <small className="error-text">{errors.phone_no}</small>}
            </div>



        <div>

          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            />
          {errors.message && <small className="error-text">{errors.message}</small>}
            </div>

          <button type="submit" className="contact-us-btn">Send</button>
        </form>
      </div>

      <div style={{ width: "100%", height: "400px" }}>
        <iframe
          src={location?.key_value}
          width="100%"
          height="100%"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactUs;
