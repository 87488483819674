import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getPageData = async (pageId) => {
  try {
    const resposne = await apiService.get(`/get-page-data/${pageId}`);
    return resposne.data;
  } catch (error) {}
};
export const getCampaignData = async (camId) => {
  try {
    const response = await apiService.get(`/get-campaign-page/${camId}`);
    return response.data;
  } catch (error) {}
};
export const addToWishlist = async (productId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      product_id: productId,
    };
    const response = await apiService.post("/add-wishlist", payload, config);
    return response.data;
  } catch (error) {
    throw error
  }
};
