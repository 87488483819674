import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});


export const subScribe = async (payload) => {
    try {
      const response = await apiService.post("/subscribe", payload);
      if (response.data?.status_code === 200) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };
 export const updateProfile = async (token, payload) => {
    try {
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await apiService.post("/edit-user-profile", payload, config);
        return response.data;
      } 
      
    } catch (error) {
      return error;
    }
  };