import React from "react";
import { APP_URL } from "../../../shared/constants";
import useNavigateShop from "../../../hooks/useNavigateShop";

const ExtraSection4 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();
  
  let res = [{ title: "Blog1" }, {}, {}, {}];
  function consolidatedData() {
    let res = {
      heading: "",
      ads: [],
    };
    let txt = sections?.find((x) => x?.key_name === "TITLE_1")?.key_value;
    res.heading = txt;
    let data = sections?.filter((x) => x?.key_name !== "TITLE_1");
    res.ads = data;
    return res;
  }
  console.log(consolidatedData()?.ads)
  return (
    <div className="container-fluid my-3">
      <h4 className="text-center pt-2">{consolidatedData()?.heading}</h4>

      <div className="row">
        {consolidatedData()?.ads?.length > 0 &&
          consolidatedData()?.ads?.map((item) => (
            <div
              className="col-6 col-sm-4 col-md-2 col-lg-3 mt-4"
              key={item?.key_name}
            >
              <div className="" onClick={()=>navigateShop(item?.link_one)}>
                <img
                  src={APP_URL + item?.key_value}
                  alt={item?.key_name}
                  class="img-fluid"
                />
                <h4 className="text-center pt-2">{item?.title}</h4>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExtraSection4;
