

import React, { useEffect, useState } from 'react'
import './faq.css'
import { faqCategorieFilter, faqCategoriesDropdown } from '../../services/apiServices';
import { useNavigate } from 'react-router-dom';
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa6";

function Faq() {
  const navigate =  useNavigate()
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeQuestion,setActiveQuestion] =  useState(0)
  const [faqCatDrop,setFaqCatDrop]  = useState([]) 
  const  [faqFilter,setFaqFilter] = useState([])

  const toggleItem = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  
  };

  useEffect(function(){
    getFaqTitle()
    getSingleFaq (1)
  },[])


  const getFaqTitle = async() => {
    const res  = await faqCategoriesDropdown()
    console.log(res)
    setFaqCatDrop(res.data)
   
  }


  const getSingleFaq = async(id) =>{
    const res = await faqCategorieFilter(id)
    setFaqFilter(res.data)
    console.log(res.data)
  }

  return (
    <div className='faq-container'>
      <div className='faq-header'>
        <h2>Frequently asked questions</h2>
        <div>
            <p>still need help?</p>
            <button onClick={() => navigate("/contact-us")}>contact us</button>
        </div>
      </div>  

      <div className='faq-subcontainer'>
        <div className='faq-title'>
          <ul>
              {faqCatDrop.map((el,ind) => <li key={el.id} onClick={() => {
                setActiveQuestion(ind);
                getSingleFaq(el.id)}
                } 
              className={`${activeQuestion === ind ? "faq-active" : ''}`}>{activeQuestion === ind ? <FaStar /> : <CiStar />}{el.name}</li>)}
          </ul>
         
        </div>
        <div className='faq-description'>
        {faqFilter.map((item, index) => (
                <div key={index} className="accordion-item">
                <div
                    className="accordion-header"
                    onClick={() => toggleItem(index)}
                >
                    <h3>{item.title}</h3>
                </div>
                <div
                    className="accordion-content"
                    style={{
                    maxHeight: activeIndex === index ? '200px' : '0',
                    transition: 'max-height 0.3s ease-in-out',
                    }}
                >
                    <p>{item.description}</p>
                </div>
                </div>
             ))}
        </div>
      </div>    
    </div>
  )
}

export default Faq
