
import { CheckoutProvider } from "./CheckoutContext";
import CheckoutDetails from "./CheckoutDetails";

const Checkout = () => {
  return (
    <CheckoutProvider>
     <CheckoutDetails />
    </CheckoutProvider>
  );
};

export default Checkout;
