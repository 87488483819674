import React from "react";
import SortingDropdown from "./SortingDropdown";
import { FaShoppingCart, FaHeart, FaList } from "react-icons/fa"; // Example icons
import "./filter.css";
import { FaListUl } from "react-icons/fa6";
import { BsFillGridFill } from "react-icons/bs";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { useShopContext } from "./ShopContext";
import { Chip } from "@mui/material";

const ShopHeader = () => {
  const { gridView, setGridView,orderFilter, setOrderFilter, productCount,selectedFilters,handleRemoveFilter  } = useShopContext();
  return (
    <div className="d-flex  flex-column flex-sm-row justify-content-between mb-1 align-items-center">
      <p
        style={{
          color: "var(--default-text-color-gentlemenz)",
        }}
      >
        {`${productCount !== 0 ? productCount || "" : "No"} Product Found`}
      </p>
   <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {selectedFilters?.map((filter, index) => (
                <Chip
                  key={index}
                  label={`${filter.type}: ${filter.value}`}
                  onDelete={() => handleRemoveFilter(filter)}
                  sx={{
                    backgroundColor: "#ff3f6c",
                    color: "#fff",
                    "& .MuiChip-deleteIcon": { color: "#fff" },
                  }}
                />
              ))}
            </div>
      <div className="d-flex justify-content-between mb-2 align-items-center">
      {/* <div className="mt-4">
          <SortingDropdown sortValue={""} setSortValue={() => {}} />
        </div> */}
     
        {/* Sorting Dropdown */}
        <div className="mt-4">
          <SortingDropdown sortValue={orderFilter} setSortValue={setOrderFilter} />
        </div>
        {/* <div className="vertical-divider"></div>
        <div className="vertical-icons">
          <FaListUl
            style={{
              fontSize: "1.5rem",
              cursor: "pointer",
              color: gridView === "1" ? "#000" : "#a6a39e",
            }}
            onClick={() => setGridView("1")}
          />

          <BsFillGridFill
            style={{
              fontSize: "1.5rem",
              cursor: "pointer",
              color: gridView === "2" ? "#000" : "#a6a39e",
            }}
            onClick={() => setGridView("2")}
          />
          <BsFillGrid3X3GapFill
            style={{
              fontSize: "1.5rem",
              cursor: "pointer",
              color: gridView === "3" ? "#000" : "#a6a39e",
            }}
            onClick={() => setGridView("3")}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ShopHeader;
