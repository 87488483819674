import { useState } from "react";
import "../css/icon.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faUser } from "@fortawesome/free-regular-svg-icons";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { handleLogout } from "../services/HeaderServices";
import toast from "react-hot-toast";
import trancateSting from "./common/trancktestring";
import { setEmptyCard } from "../Redux/Slice/userDeatails";
import { Badge } from "@mui/material";

function HeaderIcons() {
  const [hoverProfile, setHoverProfile] = useState(false);
  const useDetails = useSelector((state) => state?.userDetails?.userDetails);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const logout = async () => {
    try {
      const response = await handleLogout(token);
      if (response.status_code === 200) {
        localStorage.removeItem("token");
        navigate("/login");
        toast.success("logout successfully");
        dispatch(setEmptyCard());
      }
    } catch (error) {
      console.log("error in gettting the token ");
    }
  };
  return (
    <div className="icons">
      <div
        className="icon-wrapper"
        onMouseEnter={() => setHoverProfile(true)}
        onMouseLeave={() => setHoverProfile(false)}
      >
        <FontAwesomeIcon icon={faUser} />
        <p className="icon-text">Profile</p>

        {hoverProfile && (
          <div className="dropdown_icons">
            {useDetails?.full_name ? (
              <div className="dropdown-logged-in">
                <p className="welcome-text">
                  Hello,{" "}
                  <strong title={useDetails?.full_name}>
                    {trancateSting(useDetails?.full_name)}
                  </strong>
                </p>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li
                    className="li-gap-header"
                    onClick={() => navigate("/dashboard")}
                  >
                    Profile
                  </li>
                  <li
                    className="li-gap-header"
                    onClick={() => navigate("/dashboard/order-details")}
                  >
                    Orders Details
                  </li>
                  <li
                    className="li-gap-header"
                    onClick={() => navigate("/dashboard/pre-order")}
                  >
                    PreOrders
                  </li>
                  <li
                    className="li-gap-header"
                    onClick={() => navigate("/dashboard/address-book")}
                  >
                    Address Book
                  </li>
                  <li
                    className="li-gap-header"
                    onClick={() => navigate("/dashboard/change-password")}
                  >
                    Change Password
                  </li>
                  <li className="li-gap-header" onClick={logout}>
                    Logout
                  </li>
                </ul>
              </div>
            ) : (
              <div className="dropdown-logged-out">
                <p className="welcome-text">Welcome</p>
                <p
                  style={{ fontSize: "12px", color: "grey", marginBottom: "0" }}
                >
                  To access your account
                </p>
                <button className="login-btn">
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Login / Signup
                  </Link>
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="icon-wrapper" onClick={() => navigate("/wishlist")}>
        <FontAwesomeIcon icon={faHeart} />
        <p className="icon-text">Wishlist</p>
      </div>

      <div
        className="icon-wrapper"
        onClick={() => navigate("/cart")}
        style={{ position: "relative" }}
      >
        <FontAwesomeIcon icon={faBagShopping} />
        <p className="icon-text">Bag</p>
        {useDetails?.cart_count > 0 && (
          <Badge
            badgeContent={useDetails?.cart_count}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              position: "absolute",
              top: "-5px",
              right: "-5px",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default HeaderIcons;
