import React, { useEffect, useState } from "react";
import "./Address.css";

import Select from "react-select";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import {
  getCityList,
  getCountryList,
  getStateList,
} from "../../services/AddressService";

const AddressForm = ({
  activeTab,
  formik,

  handleBillingShippingSame,
  pincodeValidationError,
  mode="",
  isDisabled
}) => {
  const [countryData, setCountryData] = useState([]);

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [isterms, setIsterm] = useState(false);
  useEffect(() => {
    getCountryListData();
  }, []);

  useEffect(() => {
    if (isterms) {
      getStateListData(formik.values?.country_id);
      getCityListData(formik.values?.state_id);
    }
  }, [isterms]);
  useEffect(() => {
    if (formik.values?.id) {
      getStateListData(formik.values?.country_id);
      getCityListData(formik.values?.state_id);
    }
  }, [formik.values?.country, formik.values.state]);
  const getCountryListData = async () => {
    try {
      const response = await getCountryList();

      setCountryData(
        response.data?.map((country) => ({
          label: country.country_name,
          value: country.country_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };
  const getStateListData = async (id) => {
    try {
      const response = await getStateList(id);
      setStateData(
        response?.data?.map((state) => ({
          label: state.state_name,
          value: state.state_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };
  const getCityListData = async (id) => {
    try {
      const response = await getCityList(id);
      setCityData(
        response?.data?.map((city) => ({
          label: city.city_name,
          value: city.city_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };

  const handleCountryChange = async (formik, value) => {
    if (!value) {
      await formik.setFieldValue(`country_id`, "");
      await formik.setFieldValue(`state_id`, "");
      await formik.setFieldValue(`state`, "");

      await formik.setFieldValue(`city_id`, "");
      return;
    }
    await formik.setFieldValue(`country_id`, value?.value ?? "");
    await formik.setFieldValue(`country`, value?.value ?? "");
    await formik.setFieldTouched("country_id");
    await formik.setFieldValue(`state_id`, "");

    getStateListData(value?.value);
  };
  const handleStateChange = async (formik, value) => {
    await formik.setFieldValue(`state_id`, value?.value ?? "");
    await formik.setFieldValue(`state`, value?.value ?? "");
    await formik.setFieldValue(`state_name`, value?.label ?? "");
    await formik.setFieldTouched("state_id");
    await formik.setFieldValue(`city_id`, "");

    getCityListData(value?.value);
  };
  const handleCityChange = async (formik, value) => {
    await formik.setFieldValue(`city_id`, value?.value ?? "");
    await formik.setFieldValue(`city`, value?.value ?? "");
    await formik.setFieldTouched("city_id");
  };
  

  return (
    <div className="form-container">
      {activeTab === "description" && (
        <div
          className={`${" d-flex px-3"}`}
          style={{ alignItems: "center", paddingBottom: "10px" }}
        >
          <input
            className={`form-check-input mt-0 ${
              formik.errors.is_default && formik.touched.is_default
                ? "is-invalid"
                : ""
            }`}
            type="checkbox"
            checked={formik.values.is_default}
            onChange={(e) => {
              formik.setFieldValue("is_default",e.target.checked)
            //   handleBillingShippingSame(e);
            //   setIsterm(true);
            }}
            aria-describedby="invalidCheck3Feedback"
            name="is_default"
          />
          <b
            className=" d-flex login-text-primary"
            style={{
              cursor: "pointer",
              fontSize: "13px",
              color: "#1a4895!important",
              paddingLeft: "10px",
            }}
          >
            Set as default
          </b>
        </div>
      )}
      {activeTab === "specification" && (
        <div className="d-flex justify-content-between">
          {mode !== "Edit" && 
          <div
            className={`${" d-flex px-3"}`}
            style={{ alignItems: "center", paddingBottom: "10px" }}
          >
            <input
              className={`form-check-input mt-0 ${
                formik.errors.terms_agree && formik.touched.terms_agree
                  ? "is-invalid"
                  : ""
              }`}
              type="checkbox"
              checked={formik.values.terms_agree}
              value={true}
              onChange={(e) => {
                handleBillingShippingSame(e);
                setIsterm(true);
              }}
              aria-describedby="invalidCheck3Feedback"
              name="terms_agree"
            />
            <b
              className=" d-flex login-text-primary"
              style={{
                cursor: "pointer",
                fontSize: "13px",
                color: "#1a4895!important",
                paddingLeft: "10px",
              }}
            >
              Make Billing and Shipping the Same
            </b>
          </div>}
          <div
            className={`${" d-flex px-3"}`}
            style={{ alignItems: "center", paddingBottom: "10px" }}
          >
            <input
              className={`form-check-input mt-0 ${
                formik.errors.is_default && formik.touched.is_default
                  ? "is-invalid"
                  : ""
              }`}
              type="checkbox"
              checked={formik.values.is_default}
              value={true}
              onChange={(e) => {
                formik.setFieldValue("is_default",e.target.checked)

              }}
              aria-describedby="invalidCheck3Feedback"
              name="is_default"
            />
            <b
              className=" d-flex login-text-primary"
              style={{
                cursor: "pointer",
                fontSize: "13px",
                color: "#1a4895!important",
                paddingLeft: "10px",
              }}
            >
              Set as default
            </b>
          </div>
        </div>
      )}
      <h5 className="contact-heading mt-2">CONTACT DETAILS</h5>
      <div class="container px-3 py-3">
        <div class="row">
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Name
              </label>
              <input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={`form-control input-with-small-placeholder ${
                  formik.errors.name && formik.touched.name ? "is-invalid" : ""
                }`}
                placeholder="Enter Name"
                type="text"
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
          </div>
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Mobile Number
              </label>
              <input
                name="mobile_no"
                value={formik.values.mobile_no}
                onChange={formik.handleChange}
                placeholder="Mobile Number"
                type="number"
                onBlur={formik.handleBlur}
                className={`form-control ${
                  formik.errors.mobile_no && formik.touched.mobile_no
                    ? "is-invalid"
                    : ""
                }`}
              />
              {formik.errors.mobile_no && formik.touched.mobile_no && (
                <div className="invalid-feedback">
                  {formik.errors.mobile_no}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="container px-3">
        <div class="row">
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Email Address
              </label>
              <input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Email Address"
                type="email"
                onBlur={formik.handleBlur}
                className={`form-control ${
                  formik.errors.email && formik.touched.email
                    ? "is-invalid"
                    : ""
                }`}
                disabled={mode === "Edit"}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <h5 className="contact-heading my-4">BILLING ADDRESS</h5>
      <div class="container px-3 py-2">
        <div class="row">
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Address (House No, Building, Street, Area)*
              </label>
              <input
                name="address_line_one"
                value={formik.values.address_line_one}
                onChange={formik.handleChange}
                className={`form-control ${
                  formik.errors.address_line_one &&
                  formik.touched.address_line_one
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Address line one"
                type="text"
                onBlur={formik.handleBlur}
              />
              {formik.errors.address_line_one &&
                formik.touched.address_line_one && (
                  <div className="invalid-feedback">
                    {formik.errors.address_line_one}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div class="container px-3 ">
        <div class="row">
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Locality / Town*
              </label>
              <input
                name="address_line_two"
                value={formik.values.address_line_two}
                onChange={formik.handleChange}
                className={`form-control ${
                  formik.errors.address_line_two &&
                  formik.touched.address_line_two
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Address line two"
                type="text"
                onBlur={formik.handleBlur}
              />
              {formik.errors.address_line_two &&
                formik.touched.address_line_two && (
                  <div className="invalid-feedback">
                    {formik.errors.address_line_two}
                  </div>
                )}
            </div>
          </div>
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Pin Code*
              </label>
              <input
                name="pincode"
                value={formik.values.pincode}
                onChange={formik.handleChange}
                className={`form-control ${
                  formik.errors.pincode && formik.touched.pincode
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Address line one"
                type="number"
                onBlur={formik.handleBlur}
              />
              {formik.errors.pincode && formik.touched.pincode && (
                <div className="invalid-feedback">{formik.errors.pincode}</div>
              )}
              {pincodeValidationError && (
                <div style={{ color: "red" }}>{pincodeValidationError}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="container px-3 py-2">
        <div class="row">
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Select Country
              </label>

              <Select
                value={countryData?.find(
                  (country) => country.value === formik.values.country_id
                )}
                onChange={(value) => handleCountryChange(formik, value)}
                options={countryData}
                placeholder="Select Country"
                isClearable={true}
                classNamePrefix="custom-select"
              />
              {formik.errors.country_id && formik.touched.country_id && (
                <div className="" style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.country_id}
                </div>
              )}
            </div>
          </div>
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Select State
              </label>

              <Select
                defaultValue={stateData?.find(
                  (state) => state.value === formik.values.state_id
                )}
                value={stateData?.find(
                  (state) => state.value === formik.values.state_id
                )}
                onChange={(value) => handleStateChange(formik, value)}
                options={stateData || []}
                placeholder="Select State"
                isClearable={true}
                classNamePrefix="custom-select"
                isDisabled={!formik?.values?.country_id}
              />
              {formik.errors.state_id && formik.touched.state_id && (
                <div className="" style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.state_id}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="container px-3 py-2">
        <div class="row">
          <div class="col-sm">
            <div className="m-b10">
              <label className="label-title" style={{ fontSize: "13px" }}>
                Select City
              </label>
              <Select
                // defaultValue={selectedOption}
                value={cityData?.find(
                  (city) => city.value === formik.values.city_id
                )}
                onChange={(value) => handleCityChange(formik, value)}
                options={cityData}
                placeholder="Select City"
                isClearable={true}
                classNamePrefix="custom-select"
                isDisabled={!formik?.values?.state_id}
              />
              {formik.errors.city_id && formik.touched.city_id && (
                <div className="" style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.city_id}
                </div>
              )}
            </div>
          </div>
          <div class="col-sm">
            {activeTab !== "specification" && (
              <div className="my-4">
                <button
                  className="btn btn-primary px-4 float-end"
                  onClick={formik.handleSubmit}
                  disabled={isDisabled}
                >
                  CONTINUE <MdKeyboardDoubleArrowRight />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {activeTab === "specification" && (
        <div class="container px-3">
          <div class="row">
            <div class="col-sm">
              <button
                className="btn btn-primary px-4 w-100 my-4"
                onClick={formik.handleSubmit}
                isDisabled={isDisabled}
              >
                ADD NEW ADDRESS <MdKeyboardDoubleArrowRight />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressForm;
