import React, { useEffect, useState } from "react";
import "../../css/SingleProduct.css";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faHeart } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import {
  addToCart,
  addToPreorder,
  addToWishList,
  getProductImages,
  getSingleProduct,
} from "../../services/apiServices";
import ProductaSizeVaraiant from "./ProductaSizeVaraiant";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { fetchUserData } from "../../Redux/Slice/userDeatails";
import SimilarPrdocts from "./SimilarPrdocts";

const SingleProductPage = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [productDetails, setProductDetails] = useState(null);
  const [productImages, setproductImages] = useState([]);
  const [addtoCartProduct, setaddtoCartProduct] = useState({
    product_id: "",
    quantity: 1,
    variant_id: "",
    attribute_id: 0,
  });

  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const location = useLocation();
  const productId = location.state?.productId;
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    fetchProductDetails();
    fetchProductImage();
  }, [productId]);

  const validatePincode = () => {
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    if (pincodeRegex.test(pincode)) {
      setError("");
      alert("Pincode is valid!");
    } else {
      setError("Please enter a valid 6-digit pincode.");
    }
  };

  const handleVariantIdUpdate = (variantId) => {
    setaddtoCartProduct((prevState) => ({
      ...prevState,
      variant_id: variantId,
    }));
  };

  const fetchProductDetails = async () => {
    try {
      const response = await getSingleProduct(productId);
      if (response.status_code === 200) {
        setProductDetails(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchProductImage = async () => {
    try {
      const response = await getProductImages(productId);
      if (response.status_code === 200) {
        setproductImages(response.data || []);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!addtoCartProduct.variant_id) {
        toast.error("Please select a variant before proceeding.");
        return;
      }
      const payload = {
        product_id: productId,
        quantity: 1,
        variant_id: addtoCartProduct.variant_id,
        attribute_id: 0,
      };
      let flag = function modeAPICall() {
        if (location.state?.text === "Preorder") {
          return addToPreorder(token, payload);
        } else {
          return addToCart(payload);
        }
      };
      let apicall = flag();
      const response = await apicall;
      if (response.status_code === 200) {
        toast.success(response?.message);
        dispatch(fetchUserData(token));
      }
    } catch (error) {
      toast.error(
        location.state?.text === "Preorder"
          ? "Product already added to the preorder"
          : "Product already added to the cart"
      );
    }
  };

  const handleAddWishlist = async (id) => {
    const payload = {
        product_id: +id,
      };

      const response = await addToWishList(token,payload);
      if (response?.status === "success") {
        toast.success(response?.message);
        
      } else if (response === 403) {
        toast.error("Product already exists in the wishlist");
      } else {
        toast.error(response?.message);
      }
  };

  return (
    <>
      <div className="product-page">
        <div className="images-section">
          {productImages?.length === 0 && 
        <img
                src={`${AppUrl}${productDetails?.thumnail_image}`}
                alt={ `Product Image `}
                className="product-image"
              />}
          <div className="image-grid">
          
            {productImages?.map((image, index) => (
              <img
                key={index}
                src={`${AppUrl}${image.image_path}`}
                alt={image.alt || `Product Image ${index + 1}`}
                className="product-image"
              />
            ))}
          </div>
        </div>
        <div className="details-section">
          <h1 className="product-title">{productDetails?.product_name}</h1>
          <p className="product-subtitle">
            {productDetails?.slug
              ?.split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </p>
          <div className="price-section">
            <span className="current-price">
              ₹{productDetails?.sales_price}
            </span>
            <span className="original-price">
              ₹{productDetails?.original_price}
            </span>
            <span className="discount">(30% OFF)</span>
          </div>

          <ProductaSizeVaraiant
            id={productId}
            setVariantIdInParent={handleVariantIdUpdate}
          />

          <div>
            <div className="d-flex justify-content-start">
              <Button
                sx={{
                  backgroundColor: "#FF3F6C",
                  padding: "5px 30px",
                  color: "#fff",
                  border: "none",
                  margin: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#E6365B",
                  },
                }}
                onClick={() => handleSubmit()}
              >
                <FontAwesomeIcon icon={faBagShopping} /> &nbsp;&nbsp;{" "}
                {location?.state?.text ? "Preorder" : "Add to bag"}
              </Button>
              {location.state?.text === "Preorder" ? <></>:
              <Button
                sx={{
                  border: "1px solid black",
                  padding: "5px 30px",
                  color: "black",
                  backgroundColor: "transparent",
                  margin: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                }}
                onClick={() => handleAddWishlist(productId)}
              >
                <FontAwesomeIcon icon={faHeart} /> &nbsp;&nbsp; Wishlist
              </Button>}
            </div>
            <style jsx>{`
              @media (max-width: 375px) {
                .d-flex {
                  flex-direction: column; /* Stack buttons vertically */
                  align-items: flex-start; /* Align buttons to the start */
                }
              }
            `}</style>
          </div>

          <div className="delivery-options " style={{ fontSize: "12px" }}>
            <p style={{ textTransform: "uppercase", fontSize: "12px" }}>
              <strong>Delivery Options</strong>
            </p>
            <div className="pincode-container">
              <input
                type="text"
                placeholder="Enter pincode"
                className="pincode-input"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
              <button className="check-button" onClick={validatePincode}>
                Check
              </button>
              {error && (
                <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
              )}
            </div>
            <p>100% Original Products</p>
            <p>Pay on delivery might be available</p>
            <p>
              Returns and Exchanges in
              <span>{productDetails?.returning_date}</span> days
            </p>
          </div>

          <hr />
          <div className="offers-section" style={{ fontSize: "12px" }}>
            <p style={{ textTransform: "uppercase", fontSize: "17px" }}>
              <strong style={{ textTransform: "uppercase" }}>
                specification
              </strong>
            </p>

            <p
              dangerouslySetInnerHTML={{
                __html: productDetails?.specification,
              }}
            />
          </div>
          <hr />
          <div className="product-details" style={{ fontSize: "12px" }}>
            <p style={{ textTransform: "uppercase", fontSize: "17px" }}>
              <strong>Product Details</strong>
            </p>
            <p
              dangerouslySetInnerHTML={{ __html: productDetails?.description }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="container mt-4">
        <SimilarPrdocts productId={productDetails?.category_id} />
      </div>
    </>
  );
};

export default SingleProductPage;
