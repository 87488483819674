import React from "react";
import "./cart.css";
import { MdClear } from "react-icons/md";
import Counter from "../../components/Counter/Counter";
import { FaInfoCircle } from "react-icons/fa";
import { useCheckoutContext } from "./CheckoutContext";
import { APP_URL } from "../../shared/constants";

const CardList = () => {
  const {
    isLoading,
    cartListData,
    quantity,
    setQuantity,
    updateCart,
    setIsConfirmDeleteCart,
    setProductID,
  } = useCheckoutContext();
  return (
    <>
       {cartListData?.length > 0 &&
            cartListData?.map((x) => {
              return (
                <div
                  className="container-fluid p-2 mt-2"
                  style={{ border: "1px solid #eaeaec" }}
                >
                  <div className="d-flex flex-column flex-md-row  ">
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={APP_URL + x?.thumnail_image}
                        alt="product_name"
                        width={"130px"}
                        className="img-fluid responsive-img"
                      />
                      <div className="cancel-icon-img ms-auto">
                        <MdClear
                          onClick={() => {
                            setProductID(x?.product_id);
                            setIsConfirmDeleteCart(true);
                          }}
                          className="pointer"
                        />
                      </div>
                    </div>
                    <div className="px-2">
                      <p className="mb-0 cart-product-name">
                        {x?.product_name || ""}
                      </p>

                      <div className="d-flex gap-2">
                        <p className="pt-3">Qty:</p>
                        <Counter
                          initialValue={x?.item_count || 1}
                          minValue={1}
                          onChange={(value) => {
                            setQuantity(value);
                            updateCart(x, value);
                          }}
                        />
                      </div>
                      <span className="fw-bold ">Rs. {x?.original_price}</span>
                      <div className="px-2 text-secondary ">
                        <FaInfoCircle />
                        <span style={{ fontSize: "0.7rem", color: "" }}>
                          inclusive of all taxes
                        </span>
                      </div>
                    </div>
                    <div className="cancel-icon ms-auto ">
                      <MdClear
                        onClick={() => {
                          setProductID(x?.product_id);
                          setIsConfirmDeleteCart(true);
                        }}
                        className="pointer"
                      />
                    </div>
                  </div>
                </div>
              );
            })}

      {!isLoading && cartListData?.length === 0 && <p>No Cart Avaialble</p>}
    </>
  );
};

export default CardList;
