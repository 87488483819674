import axios  from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getCategoriesOptionsAPICall = async () => {
    try {
      const response = await apiService.get("/dropdown-sub-categories");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getBrandOptionsAPICall = async () => {
    try {
      const response = await apiService.get("/all-brands");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const getMainCategoryAPICall = async () => {
    try {
      const response = await apiService.get("/dropdown-main-categories");
      return response.data;
    } catch (error) {
      throw error;
    }
  };