import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./Modal.css";
const AppModal = ({ children, size = "xl", title = "",closeButton, ...props }) => {

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={size}
      scrollable
    >
      <Modal.Header closeButton={closeButton} style={{ borderBottom: "" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 style={{ fontSize: "14px", fontWeight: 500 }}> {title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>{children}</Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}></Modal.Footer>
    </Modal>
  );
};

export default AppModal;
