import React from "react";
import "./pricecard.css";
import { FaTag } from "react-icons/fa";
import { useCheckoutContext } from "./CheckoutContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
const PriceCard = () => {
  const { setIsCouponForm ,cartListData} = useCheckoutContext();
  const navigate = useNavigate();
  const { loading, error, cartTotalAmount } = useSelector(
    (state) => state?.cartAmountDetails
  );
  const handleContinueAddressPage =()=>{
    if(cartListData?.length > 0 ){
      navigate("/checkout/address")

    }else{
      toast.error("Plese add atleast one items")
    }
  }
  return (
    <div>
      <p className="coupon-text">COUPONS</p>
      <div
        className="d-flex justify-content-between"
        style={{
          marginTop: "20px",
          padding: "5px",
        }}
      >
        <button className="btn border-0 fw-bold cart-action-btn ">
          <FaTag /> Apply Coupons
        </button>
        <div>
          <button
            className=" fw-bold apply-coupon-btn "
            onClick={() => setIsCouponForm(true)}
          >
            Apply
          </button>
        </div>
      </div>
      <hr style={{ color: "#eaeaec" }} />

      {/* <p className="coupon-text">GIFTING & PERSONALISATION</p>

      <div className="gift_personalisation_cont">
        <img
          src="	https://elegra.in/static/media/gift-big.a148dbc7bb8f24266591.webp"
          class="img-fluid"
          alt=""
          width="40"
        />
        <h6 className="gif-heading">Buying for a loved one?</h6>
        <p className="my-2">
          Gift wrap and personalised message on card,
          <br /> Only for{" "}
          <span>
            <b>Free</b>
          </span>
        </p>
        <a
          data-bs-toggle="modal"
          data-bs-target="#gift_wrap_modal"
          href="/checkout/cart"
        >
          ADD GIFT WRAP
        </a>
      </div>
      <hr style={{ color: "#eaeaec" }} /> */}
      <div className="price-details">
        <p className="coupon-text">{`Price details (${
          cartTotalAmount?.total_count || ""
        } items)`}</p>

        <div className="price-details-content d-flex flex-column gap-2">
          <div>
            <span>Total Original Price</span>
            <span>Rs.{cartTotalAmount?.total_original_amount?.toFixed(2) || ""}</span>
          </div>
          {/* <div>
            <span>Yours Saving Price</span>
            <span className="text-green">Rs.2099.00</span>
          </div> */}
          <div>
            <span>Total Tax</span>
            <span className="">
              Rs.{cartTotalAmount?.total_tax_amount?.toFixed(2) || ""}
            </span>
          </div>
          <div>
            <span>Total Sales Amount</span>
            <span className="">
              Rs.{cartTotalAmount?.total_sales_amount?.toFixed(2) || ""}
            </span>
          </div>
          {cartTotalAmount?.coupen_discount_price !==0 && 
          <div>
            <span>Coupon Discount</span>
            <span className="text-green">
              Rs.{cartTotalAmount?.coupen_discount_price?.toFixed(2)|| "0"}
            </span>
          </div>}
          <div>
            <span>Shipping Fee</span>
            <span className="text-green">FREE</span>
          </div>
        </div>
        <div className="total-amount ">
          <span> Total Amount</span>
          <span>Rs.{cartTotalAmount?.total_price?.toFixed(2) || ""}</span>
        </div>
        <button
          className="btn btn-primary w-100 fw-bold continue-btn"
          onClick={handleContinueAddressPage }
        >
          CONTINUE
        </button>
      </div>
    </div>
  );
};

export default PriceCard;
