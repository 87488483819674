import React from "react";
import { MdClear } from "react-icons/md";
import PriceRangeFilter from "./PriceRangeSlider";
import CategoryFilter from "./CategoryFilter";
import BrandFilter from "./BrandFilter";
import SearchBar from "./SearchBar";
import ShopHeader from "./ShopHeader";
import { useShopContext } from "./ShopContext";
import Card from "../../../components/Card";
import PaginationIndex from "../../../components/Pagination/Pagination";
import noImage from "../../../images/nodata.png";

const ShopDetails = () => {
  const {
    categoriesOptions,
    allProducts,
    currentPage,
    priceRangeData,
    handleResetFilter,
    selectedCategory,
    selectedBrand,
    maxPrice,
    minPrice,
    orderFilter,
    productLoading,
    gotoFirstPage,
    handleNextPage,
    handlePreviousPage,
    productCount,
  } = useShopContext();

  function isElligiableClearFilter() {
    if (
      selectedCategory ||
      selectedBrand ||
      currentPage ||
      orderFilter !== "asc" ||
      priceRangeData?.min_price_amount !== minPrice ||
      priceRangeData?.max_price_amount !== maxPrice
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      {/* <Section2 /> */}

      <div className="container mt-5">
        <div className="row ">
          <div className="col-lg-3 p-3 position-sticky">
            <div
              className="d-flex justify-content-between align-items-center mb-2 pb-2 "
              //   style={{ borderBottom: "2px solid #e9e9e9" }}
            >
              {/* <HiOutlineAdjustmentsHorizontal /> */}
              <h6
                className=""
                // style={{ borderBottom: "2px solid #e9e9e9" }}
              >
                FILTERS
              </h6>
              {isElligiableClearFilter() && (
                <button
                  // className="clearFilterButton"
                  onClick={handleResetFilter}
                  style={{
                    border: "none",
                    color: "#ff3f6c",
                    backgroundColor: "inherit",
                    fontSize: "0.85rem",
                    fontWeight: 800,
                  }}
                >
                  <MdClear />
                  Clear All
                </button>
              )}
            </div>
            {/* <SearchBar /> */}
            <PriceRangeFilter />
            {categoriesOptions?.length > 0 && <CategoryFilter />}
            {/* {brandOptions?.length > 0 && <BrandFilter />} */}
          </div>

          {/* </div> */}

          <div className="col-lg-9">
            <ShopHeader />
            <div className="row">
              {productCount == 0 && (
                <h5
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <img src={noImage} alt="" className="img-fluid" width={500} />
                </h5>
              )}

              {productLoading ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div
                      className="loader"
                      style={{ width: "200px", height: "200px" }}
                    >
                      Loading....
                    </div> */}
                  </div>
                  {/* <h2 style={{ textAlign: "center", fontSize: "1rem" }}>
                    Loading......
                  </h2> */}
                  {Array.from({ length: 8 })?.map((x, index) => {
                    return (
                      <div className="col-sm-6 col-md-3 mb-4" key={index}>
                        <Card product={x} isLoading={productLoading} />
                      </div>
                    );
                  })}
                </>
              ) : (
                allProducts?.length > 0 &&
                allProducts?.map((x) => {
                  return (
                    <div className="col-sm-6 col-md-3 mb-4" key={x?.product_id}>
                      <Card product={x} isLoading={productLoading} />
                    </div>
                  );
                })
              )}
            </div>
            {!productLoading && allProducts?.length > 0 && (
              <PaginationIndex
                totalProducts={priceRangeData?.product_count}
                productsPerPage={20}
                currentPage={currentPage}
                gotoFirstPage={gotoFirstPage}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShopDetails;
