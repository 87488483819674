import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getmyOrderListDetails } from "../../../services/orderServices";

export const downloadInvoice = async (orderId, logo) => {
  try {
    const token = localStorage.getItem("token");
    const response = await getmyOrderListDetails(orderId, token);
    if (response.status_code === 200) {
      const orderDetail = response.data;

      const invoiceElement = document.createElement("div");
      invoiceElement.style.width = "100%";
      invoiceElement.style.maxWidth = "240mm";
      invoiceElement.style.padding = "10mm";
      invoiceElement.style.boxSizing = "border-box";
      invoiceElement.style.fontSize = "14px";
      invoiceElement.style.margin = "auto";
      invoiceElement.style.position = "relative";

      const productRows = orderDetail?.purchase_list
        ?.map(
          (product) =>
            `
        <tr>
          <td >${product.product_name}</td>
          <td >${product.quantity}</td>
          <td >₹${product.price}</td>
          <td >₹${product.discount_price}</td>
          <td >₹${product.tax_amount}</td>
          <td >₹${product.sales_amount}</td>
        </tr>
      `
        )
        .join("");
      invoiceElement.innerHTML = `
      <div class="invoice-buttons">
        <div class="invoicetop" style="display: flex; justify-content: center; margin: 1rem auto;">
          <main style="width: 100%; max-width: 800px; min-height: auto; padding: 1rem; box-sizing: border-box;">
            <div class="container">
              <div class="invoice_head">
                <table style="width: 100%; border-collapse: collapse;">
                  <tbody>
                    <tr>
                      <td colspan="2"><h4>Tax Invoice</h4></td>
                    </tr>
                    <tr class="top_cont">
                      <td>
                        <div><span>Order Number : </span>${
                          orderDetail.order_code
                        }</div>
                        <div><span>Mode of Payment : </span>Online Transfer</div>
                        <div><span>Order Status : </span>${
                          orderDetail.order_status
                        }</div>
                      </td>
                      <td align="right">
                        <div><span>Order Date : </span>${
                          orderDetail.order_date
                        }</div>
                        <div><span>Payment Status : </span>${
                          orderDetail.payment_status
                        }</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="invoice_address">
                <table style="width: 100%; border-collapse: collapse;">
                  <tbody>
                    <tr>
                      <td><h4><strong>Name and Address</strong></h4></td>
                      <td align="right"><h4><strong>Shipping Name & Billing Address</strong></h4></td>
                    </tr>
                    <tr>
                      <td>
                        <div>${orderDetail.customer_name}</div>
                        <div>${
                          orderDetail.billing_address.billing_address_line_one
                        }</div>
                        <div>${
                          orderDetail.billing_address.billing_pincode
                        }</div>
                        <div>Email: ${
                          orderDetail.billing_address.billing_email
                        }</div>
                        <div>Phone: ${
                          orderDetail.billing_address.billing_phone_no
                        }</div>
                      </td>
                      <td align="right">
                        <div class="ihhbc">${
                          orderDetail.shipping_address.shipping_name
                        }</div>
                        <div class="ihhbc">${
                          orderDetail.shipping_address.shipping_address_line_one
                        }</div>
                        <div class="ihhbc">${
                          orderDetail.shipping_address
                            .shipping_address_line_two || ""
                        } Pincode: ${
        orderDetail.shipping_address.shipping_pincode
      }</div>
                        <div class="ihhbc">Phone: ${
                          orderDetail.shipping_address.shipping_phone_no
                        }</div>
                        <div class="ihhbc">${
                          orderDetail.shipping_address.shipping_email
                        }</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="invoice_table table-responsive">
                <table class="table" style="width: 100%; border-collapse: collapse;">
                  <thead>
                    <tr>
                      <th align="left">Product</th>
                      <th class="title">Quantity</th>
                      <th class="title">Price</th>
                      <th class="title">Discount Amount</th>
                      <th class="title">Tax</th>
                      <th class="title">Total</th>
                    </tr>
                  </thead>
                  <tbody class="tjjnbody">
                    ${productRows}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan="4" align="left">Sub Total</th>
                      <th class="Amount_class">₹${orderDetail.total_amount}</th>
                    </tr>
                    <tr>
                      <th colspan="4" align="left">Shipping Fee</th>
                      <th class="Amount_class">₹0</th>
                    </tr>
                    <tr>
                      <th colspan="4" align="left">TOTAL AMOUNT</th>
                      <th class="Amount_class">₹${orderDetail.total_amount}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="invoice_footer">
                <div class="top_cont">
                  <h4>DECLARATION</h4>
                  <p>We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.</p>
                </div>
                <div class="bott_cont">
                  <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                      <tr>
                        <td>
                          <h4>Reg address:</h4>
                          <p>
                            Handletech Labs Private Limited <br />
                            3, Shop no A/312, Sai Arcade, Phadke Road, Near Ganpati Mandir, Dombivli, <br />
                            Kalyan, Thane, Maharashtra, 421201.
                          </p>
                          <p>
                            For any inquiries or concerns, please do not hesitate to reach out to us via email at handletechlabsprivatelimited@gmail.com or by phone at +91 8454086911. We are here to assist you promptly.
                          </p>
                        </td>
                        <td align="center">
                          <p>Purchase made on</p>
                           <img src="${logo}" alt="" style="max-width: 30%; height: auto;" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    `;

      document.body.appendChild(invoiceElement);

      const canvas = await html2canvas(invoiceElement, {
        scale: 2,
        useCORS: true,
        logging: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("invoice.pdf");

      document.body.removeChild(invoiceElement);
    } else {
      console.error("Failed to fetch order details");
    }
  } catch (error) {
    console.error("Error fetching order details:", error);
  }
};
