import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});
export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (token, { rejectWithValue }) => {
    try {
      const confiq = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiService.get("/user", confiq);
      if (response.data.status_code === 200) {
        return response.data.data;
      }
    } catch (error) {
      localStorage.removeItem("token");
    }
  }
);

const initialState = {
  userDetails: [],
  loading: true,
  error: null,
};
const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setEmptyCard: (state) => {
      state.userDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.loading = false;
      })

      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "error redux found";
      });
  },
});
export const { setEmptyCard } = userSlice.actions;

export default userSlice.reducer;
