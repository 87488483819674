import React, { useEffect, useState } from "react";
import "./RangeSlider.css";
import { useShopContext } from "../ShopContext";
const RangeSlider = () => {
  const{priceRangeData,minPrice,setMinPrice,setMaxPrice,maxPrice,getAllProducts,selectedCategory,selectedBrand,currentPage,orderFilter}=useShopContext();
  // Initialize state for min and max price
  const minimum = priceRangeData?.min_price_amount;
  const maximum = priceRangeData?.max_price_amount;
  const [isUserInteracting, setIsUserInteracting] = useState(false);

  // Event handler for input changes in range inputs
  const handleRangeInputChange = (e) => {
    const { name, value } = e.target;

   let min =minimum;
   let max =maximum;
    if (name === "range-min") {
      if (parseInt(value) <= maxPrice) {
        setMinPrice(parseInt(value));
        min = parseInt(value)
      }
    } else if (name === "range-max") {
      if (parseInt(value) >= minPrice) {
        setMaxPrice(parseInt(value));
        max = parseInt(value)
      }
    }


    // getAllProducts(
    //   selectedCategory,
    //   selectedBrand,
    //   "",
    //   currentPage ,
    //   {min_price_amount : min,max_price_amount:max},
    //   20,
    //   orderFilter
    // );
    setIsUserInteracting(true);
  };

   // Calculate percentage for slider progress
   const rangeStyle = {
    left: ((minPrice - 1000) / maximum) * 100 + "%",
    right: 100 - (maxPrice / maximum) * 100 + "%",
  };
  useEffect(()=>{
    if (isUserInteracting) {
      // Only trigger this effect after user interaction
      getAllProducts(
        selectedCategory,
        selectedBrand,
        "",
        currentPage,
        { min_price_amount: minPrice, max_price_amount: maxPrice },
        20,
        orderFilter
      );

      // Reset the interaction flag after the effect runs
      setIsUserInteracting(false);
    }
  },[minPrice,maxPrice,isUserInteracting])
  return (
    <div className="">
      <div className="wrapper">
        <h5 style={{fontSize:"14px",paddingBottom:"10px",fontWeight:700}} className="filter-header-name">Price</h5>
        <div className="rangeslider" style={{marginTop:"30px"}} >
          <div className="progress" style={rangeStyle}></div>
        </div>
        <div className="range-input" >
          <input
            type="range"
            name="range-min"
            className="range-min"
            min={minimum}
            max={maximum}
            value={minPrice}
            onChange={handleRangeInputChange}
          />
          <input
            type="range"
            name="range-max"
            className="range-max"
            min={minimum}
            max={maximum}
            value={maxPrice}
            onChange={handleRangeInputChange}
          />
        </div>
        <div className="price-input d-flex justify-content-between ">
          <div>
            <span  >Min</span>{" "}
            <span>
              <strong>Rs. {Math.round(minPrice)}</strong>
            </span>
          </div>
          <div style={{ textAlign: "right" }}>
            <span >Max</span>{" "}
            <span>
              <strong>Rs. {Math.round(maxPrice)}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
