import React from "react";

import "./ExtraSection2.css";
import { APP_URL } from "../../../shared/constants";
const ExtraSection2 = ({ sections }) => {
  let bgImg = sections?.find((x) => x?.key_name === "Image_1")?.key_value;
  let videolink = sections?.find(
    (x) => x?.key_name === "VIDEO_LINK"
  )?.key_value;
  return (
    <div
      className="container-fluid bg_img_extraction2 py-4"
      style={{ backgroundImage: `url(${APP_URL + bgImg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-8 col-sm-10">
            <iframe
              width="100%"
              height={600}
              src={videolink}
              title="Versace Spring-Summer 2023 Women’s | Fashion Show | Versace"
              frameBorder={0}
              allow="autoplay; encrypted-media"
              allowfullscreenp=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraSection2;
