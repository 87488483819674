import React from "react";
import "./Section7.css";
import { APP_URL } from "../../../shared/constants";
import { subScribe } from "../../../services/SettingsService";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { SubscribeSchema } from "./validations";
const initialState = {
  email: "",
};
const Section7 = ({ sections }) => {
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: SubscribeSchema,
    onSubmit: handleSubScription,
  });
  function consolidatedData() {
    let res = {
      title: "",
      description: "",
      backgroundImg: "",
    };
    if (sections?.length > 0) {
      let tit = sections?.find((x) => x?.key_name === "Title")?.key_value;
      let des = sections?.find((x) => x?.key_name === "Discription")?.key_value;
      let img = sections?.find((x) => x?.key_name === "Image")?.key_value;

      res.title = tit;
      res.description = des;
      res.backgroundImg = img;
    }
    return res;
  }
  async function handleSubScription(values) {
    try {
      const response = await subScribe(values);
      if (response?.status_code === 200) {
        toast.success("Subscribed successfully!");
      }
    } catch (error) {
      if (error.response?.data?.status_code === 400) {
        toast.error(error.response?.data?.data.error);
      }
    }
  }
  return (
    <section
      className="container-fluid section7-bg my-3"
      style={{
        backgroundImage: `url(${APP_URL + consolidatedData()?.backgroundImg})`,
      }}
    >
      <div className="container text-center section7-content">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            <h2 className="SubcribeClass">{consolidatedData()?.title}</h2>
            <p className="pt-1 mb-4 sub-p">{consolidatedData()?.description}</p>
            <form className="form_field">
              <div className="input-group">
                <input
                  type="email"
                  className={`email-input form-control rounded ${
                    formik.errors.email && formik.touched.email
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter your email"
                  defaultValue=""
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  required
                />
               
                <button
                  type="submit"
                  className=" btn-color-sub rounded"
                  onClick={formik.handleSubmit}
                >
                  Subscribe
                </button>
                {formik.errors.email && formik.touched.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
