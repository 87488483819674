import React from "react";
import { useSelector } from "react-redux";

const FooterContent = () => {
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  const footerContentOne = settingsData?.find(
    (item) => item?.key_name === "FOOTER_CONTENT_ONE"
  )?.key_value;
  const footerContentTwo = settingsData?.find(
    (item) => item?.key_name === "FOOTER_CONTENT_TWO"
  )?.key_value;
  return (
    <div className="container">
      <div
        className="mt-3"
        dangerouslySetInnerHTML={{ __html: footerContentOne }}
      />
      <hr />
      <ul className="list-unstyled">
        <li dangerouslySetInnerHTML={{ __html: footerContentTwo }} />
      </ul>
    </div>
  );
};

export default FooterContent;
