import React from "react";
import "./Section9.css";
import { APP_URL } from "../../../shared/constants";
import useNavigateShop from "../../../hooks/useNavigateShop";

const Section9 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();

  const data = [
    {
      src: sections?.find((x) => x?.key_name === "Image_1")?.key_value,
      text: sections?.find((x) => x?.key_name === "Title_1")?.key_value,
    },
    {
      src: sections?.find((x) => x?.key_name === "Image_2")?.key_value,
      text: sections?.find((x) => x?.key_name === "Title_2")?.key_value,
    },
    {
      src: sections?.find((x) => x?.key_name === "Image_3")?.key_value,

      text: sections?.find((x) => x?.key_name === "Title_3")?.key_value,
    },
    {
      src: sections?.find((x) => x?.key_name === "Image_4")?.key_value,

      text: sections?.find((x) => x?.key_name === "Title_4")?.key_value,
    },
  ];

  return (
    <div className="container-fluid my-5">
      <div className="row">
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <div className="section9-contents">
                <img src={APP_URL + item?.src} alt={item?.text} className="img-fluid" />
                <p className="pointer">{item?.text}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Section9;
