import React from "react";
import Card from "../../../components/Card";
import { APP_URL } from "../../../shared/constants";
import { addToWishlist } from "../../../services/SectionService";
import toast from "react-hot-toast";

const Section11 = ({ sections }) => {
  const token = localStorage.getItem("token");

  const title = sections?.find((x)=>x?.key_name === "Title")?.key_value
  const classificationData = sections?.find(
    (item) => item.key_name === "CLASSIFI"
  )?.classification_data?.[0]?.classifications_data;
  const handleAddtoCart = async (id) => {
    try {
      const response = await addToWishlist(id, token);
      if (response.status_code === 200) {
        toast.success("Product added to the wishlist");
      }
    } catch (error) {
      if (
        error.response.data.data.error ===
        "token contains an invalid number of segments"
      ) {
        toast.error("Please Login first");
      } else if (
        error.response.data.data.error === "product already exists in wishlist"
      ) {
        toast.error("Product already added in the wishlist");
      }
    }
  };
  return (
    <div className="section11-wrapper mt-5">
      <h5 className="text-center title">{title || ""}</h5>

      <div className="container-fluid mt-4">
        <div className="row">
          {classificationData?.map((item, index) => (
            <div key={item} className="col-lg-3 col-md-4 col-sm-8 mb-4">
              <Card

                isProgress={true}
                height="500px"
                progressValue={item?.quantity}
                // handleAddtoCart={()=>handleAddtoCart(+item?.product_id)}
                product={item}
                rating={true}

              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section11;
