import React from "react";
import ShopDetails from "./ShopDetails";
import { ShopProvider } from "./ShopContext";

const Shop = () => {
  return (
    <ShopProvider>
      <ShopDetails />
    </ShopProvider>
  );
};

export default Shop;
