import React, { useEffect, useState } from "react";
import "./Footer.css";
import { useSelector } from "react-redux";
import { getSubPage } from "../../services/FooterServices";
import { useLocation, useNavigate } from "react-router-dom";
import FooterContent from "./FooterContent";
import orginal from "../../images/orginal.png";
import retrunImg from "../../images/retrun.png";

const Footer = () => {
  const location = useLocation();
  const [subPageData, setSubPageData] = useState([]);
  const onlineShopping = [
    { text: "Continue Shopping", href: "/shop" },
    { text: "FAQ", href: "/faq" },
    { text: "Blogs", href: "/blog" },
    { text: "Contact Us", href: "/contact-us" },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    getSubpageData();
  }, []);

  const getSubpageData = async () => {
    try {
      const response = await getSubPage();
      setSubPageData(response.data);
    } catch (error) {}
  };
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  const siteName = settingsData?.find(
    (item) => item?.key_name === "SITE_NAME"
  )?.key_value;

  const phoneOne = settingsData?.find(
    (item) => item?.key_name === "PHONE_ONE"
  )?.key_value;
  const phoneTwo = settingsData?.find(
    (item) => item?.key_name === "PHONE_TWO"
  )?.key_value;
  const emailOne = settingsData?.find(
    (item) => item?.key_name === "EMAIL_ONE"
  )?.key_value;
  const emailTwo = settingsData?.find(
    (item) => item?.key_name === "EMAIL_TWO"
  )?.key_value;
  const addressOne = settingsData?.find(
    (item) => item?.key_name === "ADDRESS_ONE"
  )?.key_value;
  const addressTwo = settingsData?.find(
    (item) => item?.key_name === "ADDRESS_TWO"
  )?.key_value;
  const siteLogo = settingsData?.find(
    (item) => item?.key_name === "SITE_LOGO"
  )?.key_value;
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  return (
    <footer className="container-fluid footer mt-5">
      <div className="container  pt-5 ">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="footer_links">
              <h6>ONLINE SHOPPING</h6>
              <ul>
                {onlineShopping?.map((item) => (
                  <li
                    className="pointer"
                    onClick={() => navigate(item?.href)}
                    key={item.text}
                  >
                    {item?.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="footer_links">
              <h6>CUSTOMER POLICIES</h6>
              <ul>
                {subPageData?.map((item) => (
                  <li
                    key={item?.page_id}
                    className="pointer"
                    onClick={() =>
                      navigate("/sup-page", {
                        state: { pageId: item?.page_id },
                      })
                    }
                  >
                    {item?.page_name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 col-12">
            <div className="footer_links">
              <h6>{siteName}</h6>
              <img
                src={AppUrl + siteLogo}
                alt=""
                className="img-fluid"
                width="160"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="footer_links">
              <ul>
                <li className="d-flex  align-items-center">
                  <img src={orginal} alt="" className="img-fluid" width="50" />
                  <div>
                    <h6>100% ORIGINAL</h6>
                    <p>guarantee for all products at {siteName}</p>
                  </div>
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <img
                    src={retrunImg}
                    alt=""
                    className="img-fluid"
                    width="50"
                  />
                  <div>
                    <h6>Return within 14 days</h6>
                    <p>of receiving your order</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <span dangerouslySetInnerHTML={{ __html: addressOne }}></span>
            <span dangerouslySetInnerHTML={{ __html: addressTwo }}></span>
          </div>
          <div className="col-md-6 text-start text-sm-end col-lg-6 col-sm-6 col-12">
            Phone: {phoneOne},{phoneTwo}
            <br />
            <br />
            Email: {emailOne}, {emailTwo}
          </div>
        </div>
        <div className="row footer-sitename">
          <div className="col-md-4 col-sm-12 col-12">
            <p>
              In case of any concern, <a href="/contact-us">Contact Us</a>{" "}
            </p>
          </div>
          <div className="col-md-4 col-sm-12 col-12">
            <p>
              © {new Date().getFullYear()} {siteName}. All rights reserved.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 col-12">
            <p className="text-start text-md-end ">Power By FolconEngine</p>
          </div>
        </div>
        {location.pathname === "/" && <FooterContent />}
      </div>
    </footer>
  );
};

export default Footer;
