import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { getOrderList } from "../../../services/orderServices";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { downloadInvoice } from "./downloadInvoice";

const OrdersList = () => {
  const token = localStorage.getItem("token");
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);

  useEffect(() => {
    getOrderData();
  }, []);

  const getOrderData = async () => {
    try {
      const response = await getOrderList(token);
      setOrders(response.data);
    } catch (error) {}
  };

  // Calculate current orders
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  // Change page
  const logo = settingsData?.find((item) => item.key_name === "SITE_LOGO")?.key_value;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleDownloadInvoice = (orderId) => {
    const appImgae = AppUrl + logo;
    downloadInvoice(orderId, appImgae);
  };
  return (
    <div
      className="account-card h-100"
      style={{  overflowY: "auto", scrollbarWidth: "thin" }}
    >
      <div className="table-responsive table-style-1">
        <table className="table table-hover mb-3">
          <thead>
            <tr>
              <th>Order</th>
              <th>Date Purchased</th>
              <th>Status</th>
              <th>Total</th>
              <th className="text-center">Action</th>
              <th className="text-center">Invoice</th>
            </tr>
          </thead>
          <tbody>
            {currentOrders.length > 0 ? (
              currentOrders.map((order, index) => (
                <tr key={index}>
                  <td>
                    <span className="fw-medium">{order.order_code}</span>
                  </td>
                  <td>{order.order_date}</td>
                  <td>
                    <span
                      className={`badge ${
                        order.order_status === "Pending"
                          ? "bg-warning"
                          : order.order_status === "Completed"
                          ? "bg-success"
                            : order.order_status === "cancelled"
                          ? "bg-danger"
                          : "bg-secondary"
                      }`}
                    >
                      {order.order_status}
                    </span>
                  </td>
                  <td>₹{order.total_amount?.toFixed(2)}</td>
                  <td className="text-center">
                    <a
                      className="text-underline p-0"
                      onClick={() =>
                        navigate("/dashboard/view", {
                          state: { orderId: order?.order_id },
                        })
                      }
                    >
                      <FaEye />
                    </a>
                  </td>
                  <td className="text-center">
                    <button
                      className="border-0 bg-transparent text-underline p-0"
                      onClick={() => handleDownloadInvoice(order?.order_id)}
                    >
                      <FaDownload />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No orders available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-center">
        <nav>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {Array.from(
              { length: Math.ceil(orders.length / ordersPerPage) },
              (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                >
                  <button className="page-link" onClick={() => paginate(i + 1)}>
                    {i + 1}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${
                currentPage === Math.ceil(orders.length / ordersPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(orders.length / ordersPerPage)
                }
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default OrdersList;
