import axios from "axios";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getOrderList = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get("/my-order-list", config);
    return response.data;
  } catch (error) {}
};

export const getSingleOrderDetail = async (orderId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get(
      `/my-order-detail/${orderId}`,
      config
    );
    return response.data;
  } catch (error) {}
};

export const orderReturnProduct = async (orderReturnRequestCode, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      order_code: orderReturnRequestCode,
    };
    const response = await apiService.post(
      "/order-return-request",
      body,
      config
    );
    if (response.data.status_code === 200) {
      toast.success("Order Return Successfully");
    }
    return response.data;
  } catch (error) {}
};

export const cancelOrder = async (orderCode, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      order_code: orderCode,
    };
    const response = await apiService.post("/my-order-cancel", body, config);
    if (response.data.status_code === 200) {
      toast.success("Order Cancel Successfully");
    }
    return response.data;
  } catch (error) {
    console.error("Error cancelling order:", error);
    throw error;
  }
};

export const getmyOrderListDetails = async (id, token) => {

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get(`/my-order-detail/${id}`, config);
    return response.data
  } catch (error) {}
};
//   try {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     const response = await apiService.get(`/my-order-detail/${id}`, config);
//     return {
//       status_code: response.status,
//       data: response.data
//     };
//   } catch (error) {
//     console.error("Error fetching my order list details:", error);
//     return {
//       message: "An error occurred while fetching orders List details"
//     };
//   }
// } else {
//   return {
//     status_code: 401,
//     message: "Please Login First"
//   };
// };
export const getPreOrderList = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get("/get-pre-order-cart", config);
    return response.data;
  } catch (error) {}
};