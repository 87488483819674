import React, { useEffect, useState } from "react";
import EmptyCard from "./EmptyCard";
import Card from "./WishlistCard";
import { useNavigate } from "react-router-dom";
import { removeWishList, userWishList } from "../../services/CartService";
import { APP_URL } from "../../shared/constants";

const Wishlist = () => {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(window.innerWidth);

  const token = localStorage.getItem("token");
  const [wishListData, setwishListData] = useState([]);
  // const settingsData = useSelector(
  //   (state) => state?.settings?.settingsData
  // );
  const [isLoading, setIsLoading] = useState(true);
  const [loadingIds, setLoadingIds] = useState([]);
  // const dispatch =useDispatch();
  useEffect(() => {
    fetchWishList();
  }, []);
  const fetchWishList = async () => {
    setIsLoading(true);
    try {
      const response = await userWishList(token);
      if (response.data) {
        setwishListData(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch wishlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const wishListRemove = async (id) => {
    setLoadingIds((prev) => [...prev, id]);
    try {
      const response = await removeWishList(id, token);
      if (response?.status_code === 200) {
        // fetchWishList();
        setwishListData((prev) =>
          prev.filter((item) => item.product_id !== id)
        );
      }
    } catch (error) {
      console.error("Failed to remove item from wishlist:", error);
    } finally {
      setLoadingIds((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  const handleRemoveWishlistCart = (data) => {
    wishListRemove(data?.product_id);
  };
  return (
    <div className="container mt-4">
      {wishListData?.length === 0 ? 
      <EmptyCard />:
      // <div className="container-fluid mt-4">
        <div className="row">
          {wishListData?.length > 0 &&
            wishListData?.map((item, index) => (
              <div
                key={item}
                className=" col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-4 mb-4"
              >
                <Card
                  productImg={APP_URL + item?.thumnail_image}
                  brandName={item?.brand_name}
                  productDes={item?.product_name}
                  originalPrice={item?.original_price}
                  discountPrice={""}
                  productDetails={item}
                  handleRemoveWishlist={() => handleRemoveWishlistCart(item)}
                />
              </div>
            ))}
        {/* </div> */}
      </div>}
    </div>
  );
};

export default Wishlist;
