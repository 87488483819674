import React, { lazy, useEffect } from "react";
import Header from "./components/Header";

import "../src/css/Custume.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home/Home";
import { Route, Router, Routes } from "react-router-dom";
import "./App.css";
import Checkout from "./pages/Checkout/Checkout";
import CheckoutAddress from "./pages/CheckoutAddress/Checkout";
import Footer from "./pages/Footer/Footer";
import Pages from "./pages/Suppages/Pages";
import Dashboard from "./pages/Dashboard/DashboardPage/Dashboard";
import OrdersList from "./pages/Dashboard/OrdersList/OrdersList";
import Profile from "./pages/Dashboard/Profile/Profile";
import OrderView from "./pages/Dashboard/OrdersList/OrderView";
import Login from "./components/login/Login";
import Blog from "./pages/blog/Blog";
import { Toaster } from "react-hot-toast";
import SingleProductPage from "./pages/Shop/SingleProductPage";
import ChangePassword from "./pages/Dashboard/Change Password/ChangePassword";
import AddressBook from "./pages/Dashboard/AddressBook/AddressBook";
import ForgetPassword from "./components/forgetpassword/ForgetPassword";
import ExtraSections from "./pages/ExtraSections/ExtraSections";
import Faq from "./pages/faq/Faq";
import ContactUs from "./pages/contact-us/ContactUs";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import Wishlist from "./pages/Wishlist/Wishlist";
import ProtectedRoute from "./components/ProductRoute/ProtectedRoute";
import Cart from "./pages/cart/Cart";
import PreOrder from "./pages/Dashboard/PreOrder/PreOrder";
import Shop1 from "./pages/Shop1/Shop/Shop";
import { useSelector } from "react-redux";
const App = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector(
    (state) => state?.userSettingData?.settingData
  );
  const primaryColor = settingsData?.find(
    (item) => item?.key_name === "PRIMARY_COLOR"
  )?.key_value;
  const secondryColor = settingsData?.find(
    (item) => item?.key_name === "SECONDARY_COLOR"
  )?.key_value;
  const darkColor = settingsData?.find(
    (item) => item?.key_name === "DARK_COLOR"
  )?.key_value;
  const lightColor = settingsData?.find(
    (item) => item?.key_name === "LIGHT_COLOR"
  )?.key_value;
  const siteName = settingsData?.find(
    (item) => item?.key_name === "SITE_NAME"
  )?.key_value;
  const sitelogo = settingsData?.find(
    (item) => item?.key_name === "SITE_LOGO"
  )?.key_value;
  useEffect(() => {
    if (siteName) {
      document.title = siteName;
    }
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    const faviconUrl = AppUrl + sitelogo;
    link.href = faviconUrl;
    const root = document.documentElement;
    root.style.setProperty("--primary-color-gentlemenz", primaryColor);
    root.style.setProperty("--secondry-color-gentlemenz", secondryColor);
    root.style.setProperty("--hover-color-gentlemenz", darkColor);
    root.style.setProperty("--primary-light-color-gentlemenz", lightColor);
  }, [settingsData]);
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            // background: "var( --primary-color-gentlemenz)",
            // color: "#fff",
          },
        }}
      />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/checkout/cart"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route path="/single-product" element={<SingleProductPage />} />
        <Route
          path="/checkout/address"
          element={
            <ProtectedRoute>
              <CheckoutAddress />
            </ProtectedRoute>
          }
        />
        <Route path="/sup-page" element={<Pages />} />
        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route path="order-details" element={<OrdersList />} />
          <Route path="address-book" element={<AddressBook />} />
          <Route path="pre-order" element={<PreOrder />} />

          <Route index element={<Profile />} />
          <Route path="view" element={<OrderView />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="wishlist" element={<Wishlist />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<Faq />} />

        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/page/:slug" element={<ExtraSections />} />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/shop" element={<Shop1 />} />
      </Routes>
      <Footer />
    </>
  );
};
export default App;
