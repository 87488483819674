import React from "react";
import "./Section10.css";
import useNavigateShop from "../../../hooks/useNavigateShop";

const Section10 = ({ sections }) => {
  const { navigateShop } = useNavigateShop();

  const adsData = sections?.find((item) => item.key_name === "ADS")?.ads_data;
  const title = sections?.find((item) => item.key_name === "Title")?.key_value;
  const Appurl = process.env.REACT_APP_IMAGE_URL;

  return (
    <div className="container-fluid my-3">
      <h2 className="section-title text-center">{title}</h2>
      <div className="row">
        {adsData?.map((item, index) => (
          <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12 mb-4">
            <img
              src={Appurl + item?.image_path}
              alt={item?.name}
              className="img-fluid section10-img"
              onClick={()=>navigateShop(item?.link_one)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section10;
