import axios  from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

export const getAllProductsAPICall = async (payload) => {
    try {
      const response = await apiService.post("get-shop-products", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const getRangeForPagination = async () => {
    try {
      const respones = await apiService.get("/price-page-range-data");
      return respones.data;
    } catch (error) { 
       
    }
  };
  export const getSingleProduct = async (id) => {
    try {
      const response = await apiService.get(`/get-product/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const getAssetsForProduct = async (id) => {
    try {
      const response = await apiService.get(`/get-product-assets/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const getImageVarients = async (id) => {
    try {
      const response = await apiService.get(`/get-product-variant/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const getSimilarProduct = async (id) => {
    try {
      const response = await apiService.get(`/get-similar-products/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const addToWishList = async (token, id) => {
    try {
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const body = {
          product_id: id,
        };
        const response = await apiService.post("/add-wishlist", body, config);
        return response?.data;
      } else {
        return {
          status: 401,
          message: "Please Login First",
        };
      }
    } catch (error) {
      return 403;
    }
  };
  // Add To Cart API

export const addToCartApi = async (token, payload) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await apiService.post("/add-to-cart", payload, config);
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First",
      };
    }
  } catch (error) {
    return error;
  }
};
