import React, { useEffect, useState } from 'react'
import './blog.css'
import { BlogsCategoryApi, BlogsCategoryData, BlogsListApi, BlogsTagsApi, BlogsTagsData, resentBlogs, SingleBlog } from '../../services/apiServices'



function Blog() {

    const Appurl = process.env.REACT_APP_IMAGE_URL
    const  [categoriesDropdown,setCatogriesDropdown] = useState([])
    const  [recentDropdown,setRecentDropdown] = useState([])

    const  [tagsDrops,setTagsDrops] = useState([])
    const [blog,setBlog]  = useState([])

    const [singleBlog, setSingleBlog] = useState(null)
     useEffect( function(){
         getCategoriesDropdown()
         getRecentBlog()
         getTagBlog()
         getBlog()
    },[])

    const getCategoriesDropdown = async() =>{

        try {
            const res = await BlogsCategoryApi()
            if(res.status_code === 200){
                setCatogriesDropdown(res.data)

            }
        } catch (error) {
            console.log("Error in category drop down")
        }
        
    }

    const getRecentBlog = async() => {
        try {
            const res = await resentBlogs()
            if(res.status_code === 200){
                setRecentDropdown(res.data)

            }

        } catch (error) {
            console.log("Error in recent drop down")
            
        }
    }

    const getTagBlog = async () =>{
        try {
            const res = await BlogsTagsApi()
            setTagsDrops(res?.data)
        } catch (error) {
            console.log("Error in Tags API")
        }
    }
    const getBlog = async () =>{
        try {
            const res  = await BlogsListApi()

            setBlog(res.data)
        } catch (error) {
            console.log("Error in Blog api")
        }
    }
// 
    const getSingleCategory =  async (id) => {
        const res = await BlogsCategoryData(id)
        setBlog(res.data)
        setSingleBlog(null)
 
    } 
    const getSingleTag = async (id) => {
        const res  =  await BlogsTagsData(id)
        setSingleBlog(null)
        setBlog(res.data)

    }


    const getSingleBlog = async(id) => {
        const res = await SingleBlog(id)
        console.log(res)
        try {
            if(res.status_code === 200){
                setSingleBlog(res.data)
            }
        } catch (error) {
            console.log("Error in getting single blog")
        }

    }

  return (
    <div className = "blog-container">
       

       {singleBlog ? <div className='single-blog-container'>

                <div className='single-blog-img-container'>
                    <img src={`${Appurl}/${singleBlog.image_path}`} alt="" />
                </div>

                <h2 className='single-blog-title'>{singleBlog?.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: singleBlog?.message }} />

           </div> : <div className = "blog-container-lf">

          {blog.map(el =>  <div className = "blog-cards" key = {el.blog_id}>
                <div className = "blog-img-container">
                    <img src={`${Appurl}/${el.image_path}`} alt={el.title} />
                </div>
                <h6>{el.title.length >40 ? el.title.slice(0,40) + "...." : el.title }</h6>
                <p onClick={ () => getSingleBlog(el.blog_id)}>read more</p>
            </div>)}
            
       </div>}



        {/* categoty */}
        <div className = "blog-container-rt">
            <div className = "blog-category">
                <h4>Categories</h4>
                <ul className = "category-ul">
                    {categoriesDropdown.map(el => <li key = {el.id} onClick={ () => getSingleCategory(el.id)}>{el.name}</li>)}
                </ul>
            </div>


            {/* recent post */}
            <div className = "blog-category blog-recent-post">
                <h4>Recent Posts</h4>
                    <ul>
                       {recentDropdown.map(el =>  <li className='rec-container' key={el.blog_id} onClick = {() => getSingleBlog(el.blog_id)}> 
                            <div className = "blog-rec-img-container">
                                <img src={`${Appurl}/${el.image_path}`}alt={el.blog_category_name}/>
                            </div>
                            <p>{el.title}</p>
                        </li>)}
                    </ul>
            </div>

            {/* tags */}

            <div className = "blog-category blog-popular-tags">
                <h4>Popular Tags</h4>
                 <ul>
                    {tagsDrops.map(el => <li key = {el.id} onClick = {() => getSingleTag(el.id)}>{el.name}</li>)}
                 </ul>
            </div>
        </div>
    </div>
  )
}

export default Blog
